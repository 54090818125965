import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Impressum from './Impressum';
import Kontakt from './Kontakt';
import PrivacyText from './PrivacyText';
import DataVisualizationThree from '../../components/Pictures/DataVisualizationThree.png';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

const DatenschutzText = () => {
  const [datenschutz, setDatenschutz] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/dataprotection?populate=background`)
      .then((response) => {
        console.log('Section1 data:', response.data);
        setDatenschutz(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section1 content:', error);
      });
  }, []);

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Grid id='datenschutz' style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
      <header style={{ width: '100%', height: '100%', position: 'relative' }}>
        <img
          src={`${baseURL}${datenschutz.background?.data?.attributes?.url}`}
          alt='DataVisualization'
          width='100%'
          height='100%'
          style={{ marginTop: '-28%' }}
        />
        <Grid
          container
          spacing={2}
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          marginLeft='12%'
          marginTop={isMobile ? '-2%' : isTablet ? '-5%' : '-10%'}
          marginBottom={isMobile ? '20px' : '80px'}
        >
          <Typography variant={isMobile ? 'subtitle2' : isTablet ? 'h5' : 'h4'}>
            <h1 style={{ color: datenschutz.titleColor }}>
              {isDeutsch ? datenschutz.title : datenschutz.titleEn}
            </h1>
          </Typography>
        </Grid>
      </header>
      <body>
        <Typography
          id='dazenschutz'
          variant='body1'
          style={{
            width: '76%',
            paddingLeft: '12%',
          }}
        >
          <div>
            <Typography variant={isMobile ? 'h6' : 'h4'}>
              {isDeutsch ? datenschutz.datenschutzInfo : datenschutz.datenschutzInfoEn}
            </Typography>
            <br />
            <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
              {isDeutsch ? datenschutz.datenschutzInfoText : datenschutz.datenschutzInfoTextEn}
            </Typography>
          </div>
          <Typography variant='body1' style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            <ol>
              <li>{isDeutsch ? datenschutz.datenschutzList1 : datenschutz.datenschutzList1En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList2 : datenschutz.datenschutzList2En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList3 : datenschutz.datenschutzList3En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList4 : datenschutz.datenschutzList4En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList5 : datenschutz.datenschutzList5En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList6 : datenschutz.datenschutzList6En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList7 : datenschutz.datenschutzList7En}</li>
              <li>{isDeutsch ? datenschutz.datenschutzList8 : datenschutz.datenschutzList8En}</li>
            </ol>
          </Typography>
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            1.
            {isDeutsch ? datenschutz.list1Title : datenschutz.list1TitleEn}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list1Text : datenschutz.list1TextEn}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            2.
            {isDeutsch ? datenschutz.list2Title : datenschutz.list2TitleEn}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            <text>{isDeutsch ? datenschutz.list2Text1 : datenschutz.list2Text1En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text12 : datenschutz.list2Text12En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text13 : datenschutz.list2Text13En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text14 : datenschutz.list2Text14En}</text>
            <br />
            <br />
            <text>{isDeutsch ? datenschutz.list2Text2 : datenschutz.list2Text2En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text21 : datenschutz.list2Text21En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text22 : datenschutz.list2Text22En}</text>
            <br />
            <text>{isDeutsch ? datenschutz.list2Text23 : datenschutz.list2Text23En}</text>
            <br />
            <br />
            <text>
              {datenschutz.list2Text3email}&nbsp;
              <a href={`mailto: ${datenschutz.list2email}`}>{datenschutz.list2email}</a>
            </text>
            <br />
            <text>{datenschutz.list2Text2Tel}</text>
            <br />
            <text>{datenschutz.list2Text2Fax}</text>
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list2Text3 : datenschutz.list2Text3En}
            &nbsp;
            <a href={`mailto: ${datenschutz.list2email}`}>{datenschutz.list2email}</a>&nbsp;
            {isDeutsch ? datenschutz.list2Text4 : datenschutz.list2Text4En}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            3.
            {isDeutsch ? datenschutz.list3Title : datenschutz.list3TitleEn}
          </Typography>
          <h3>{isDeutsch ? datenschutz.list3Subtitle : datenschutz.list3SubtitleEn}</h3>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list3Text : datenschutz.list3TextEn}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            4.
            {isDeutsch ? datenschutz.list4Title : datenschutz.list4TitleEn}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list4Text : datenschutz.list4TextEn}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            5.
            {datenschutz.list5Title}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list5Text : datenschutz.list5TextEn}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            6.
            {isDeutsch ? datenschutz.list6Title : datenschutz.list6TitleEn}
          </Typography>
          {isDeutsch ? datenschutz.list6Subtitle : datenschutz.list6SubtitleEn}
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            <ul>
              <li>{isDeutsch ? datenschutz.list6list1 : datenschutz.list6list1En}</li>
              <li>{isDeutsch ? datenschutz.list6list2 : datenschutz.list6list2En}</li>
              <li>{isDeutsch ? datenschutz.list6list3 : datenschutz.list6list3En}</li>
              <li>{isDeutsch ? datenschutz.list6list4 : datenschutz.list6list4En}</li>
              <li>{isDeutsch ? datenschutz.list6list5 : datenschutz.list6list5En}</li>
              <li>{isDeutsch ? datenschutz.list6list6 : datenschutz.list6list6En}</li>
              <li>{isDeutsch ? datenschutz.list6list7 : datenschutz.list6list7En}</li>
            </ul>
          </Typography>
          <Typography
            style={{
              textAlign: 'justify',
              textJustify: 'inter-word',
              border: '1px solid black',
              margin: '10px',
              padding: '5px',
            }}
          >
            <h4>{isDeutsch ? datenschutz.list6Subtitle2 : datenschutz.list6Subtitle2En}</h4>
            <text>
              {isDeutsch ? datenschutz.list6Text : datenschutz.list6TextEn}
              &nbsp;
              <a href={`mailto: ${datenschutz.list6TextEmail}`}>{datenschutz.list6TextEmail}</a>
            </text>
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            7.
            {isDeutsch ? datenschutz.list7Title : datenschutz.list7TitleEn}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list7Text : datenschutz.list7TextEn}
          </Typography>
          <br />
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            8.
            {isDeutsch ? datenschutz.list8Title : datenschutz.list8TitleEn}
          </Typography>
          <Typography style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            {isDeutsch ? datenschutz.list8Text : datenschutz.list8TextEn}
          </Typography>
        </Typography>
      </body>
    </Grid>
  );
};

export default DatenschutzText;
