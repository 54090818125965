import axios from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;
const loginUser = async (
  email,
  password,
  isRegisterMode,
  companyName,
  companyType,
  companyZipcode,
  companyLocation,
  selectedCountry,
  contactFirstname,
  contactLastname,
  industrySector,
) => {
  try {
    if (isRegisterMode) {
      // Registration mode
      console.log('Attempting to register user...');
      const payload = {
        username: email,
        email: email,
        password: password,
        companyName: companyName,
        companyType: companyType,
        companyZipcode: companyZipcode,
        companyLocation: companyLocation,
        selectedCountry: selectedCountry,
        contactFirstname: contactFirstname,
        contactLastname: contactLastname,
        industrySector: industrySector,
      };
      console.log('Payload:', payload);

      const response = await axios.post(`${baseURL}/api/auth/local/register`, payload);
      console.log('Registration successful:', response.data);

      // Check if email confirmation is required
      if (response.data.user && response.data.user.confirmed === false) {
        console.log('Email confirmation required. Please check your email.');
        return {
          message: 'Registration successful. Please check your email to confirm your account.',
        };
      } else {
        // Automatically login after registration if no confirmation is required
        const loginResponse = await axios.post(`${baseURL}/api/auth/local`, {
          identifier: email,
          password: password,
        });
        console.log('Login successful:', loginResponse.data);

        // Return both user data and JWT token
        return {
          user: loginResponse.data.user,
          jwt: loginResponse.data.jwt,
        };
      }
    } else {
      // Login mode
      console.log('Attempting to login user...');
      const response = await axios.post(`${baseURL}/api/auth/local`, {
        identifier: email,
        password: password,
      });
      console.log('Login successful:', response.data);

      // Return both user data and JWT token
      return {
        user: response.data.user,
        jwt: response.data.jwt,
      };
    }
  } catch (error) {
    console.error(
      'Error during login/registration:',
      error.response ? error.response.data : error.message,
    );
    throw new Error(error.response ? error.response.data.error.message : error.message);
  }
};

export default loginUser;
