import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import { Menu } from '@mui/material';

export default function CheckBoxTen() {
  const { aufwandTen, setAufwandTen } = useMyContext();
  const { userAmountFrom, setUserAmountFrom } = useMyContext();
  const { userAmountTo, setUserAmountTo } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandTen);

  const handleUpdate = async (value: any, from: number, to: number) => {
    try {
      const updates = {
        aufwandTen: value,
        userAmountFrom: from,
        userAmountTo: to,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandTen(value); // Update local context/state immediately
    let from = 1;
    let to = 2;

    switch (value) {
      case '1-3':
        from = 1;
        to = 3;
        break;
      case '4-10':
        from = 4;
        to = 10;
        break;
      case 'Unbegrenzt':
        from = 11;
        to = 1000;
        break;
      case 'none':
        from = 0;
        to = 0;
        break;
      default:
        from = 0;
        to = 0;
    }

    setUserAmountFrom(from);
    setUserAmountTo(to);
    handleUpdate(value, from, to); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandTen) {
      setAufwandTen(selectedValue);
      setSelectedValue(aufwandTen);
      setUserAmountFrom(userAmountFrom);
      setUserAmountTo(userAmountTo);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Anzahl der Benutzerkonten
        </FormLabel>
        <Tooltip
          title='Wie viele Benutzerkonten benötigen Sie?'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Wählen Sie ein Element aus</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Wählen Sie ein Element aus'
          onChange={handleChange}
        >
          <MenuItem value={'1-3'}>1-3</MenuItem>
          <MenuItem value={'4-10'}>4-10</MenuItem>
          <MenuItem value={'Unbegrenzt'}>Unbegrenzt</MenuItem>
          <MenuItem value={'none'}>Keine Angabe</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
