import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

export default function PrivacyText() {
  const [datenschutz, setDatenschutz] = useState<any>({});

  useEffect(() => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseURL}/api/footer`)
      .then((response) => {
        console.log('Data Protection data:', response.data);
        setDatenschutz(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching data protection content:', error);
      });
  }, []);

  const { isDeutsch } = useLanguage();

  return (
    <Link color='inherit' href={'/datenschutz'}>
      {isDeutsch ? datenschutz.pageToData : datenschutz.pageToDataEn}
    </Link>
  );
}
