import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

const InfoSection3 = () => {
  const [infoSection2, setInfoSection2] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/info-section2?populate=logo`)
      .then((response) => {
        console.log('Section1 data:', response.data);
        setInfoSection2(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section1 content:', error);
      });
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: infoSection2.primaryColorLight || '#005B7F',
        main: infoSection2.primaryColorMain || '#009374',
        dark: infoSection2.primaryColorDark || '#005946',
        contrastText: infoSection2.primaryContrastText || '#fff',
      },
      secondary: {
        light: infoSection2.secondaryColorLight || '#0080b2',
        main: infoSection2.secondaryColorMain || '#005B7F',
        dark: infoSection2.secondaryColorDark || '#0080b2',
        contrastText: infoSection2.secondaryContrastText || '#fff',
      },
    },
  });

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !animationPlayed) {
          setIsInView(true);
          setAnimationPlayed(true);
        } else {
          setIsInView(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const section = sectionRef.current;
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, [animationPlayed]);
  return (
    <ThemeProvider theme={theme}>
      <div id='section-one' style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
        <Grid
          container
          spacing={8}
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent='center'
          alignContent='center'
          marginTop={isMobile ? '' : '50px'}
        >
          <Grid
            item
            xs={isMobile ? 12 : isTablet ? 6 : 12}
            md={6}
            flexDirection='column'
            container
            justifyContent={isMobile ? 'flex-end' : isTablet ? 'center' : 'center'}
            alignItems={isMobile ? 'center' : isTablet ? 'center' : 'center'}
            ref={sectionRef}
          >
            <Box
              sx={{
                backgroundImage: `url(${baseURL}${infoSection2.logo?.data?.attributes?.url})`,
                backgroundPosition: isMobile ? 'center' : isTablet ? 'center' : 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: isMobile ? 300 : isTablet ? 300 : 630,
                height: isMobile ? '480px' : isTablet ? '580px' : '780px',
                width: isMobile ? '300px' : isTablet ? '350px' : '630px',
                marginTop: isMobile ? '-130px' : isTablet ? '-40px' : '-40px',
              }}
            ></Box>
          </Grid>
          <Grid
            ref={sectionRef}
            item
            xs={isMobile ? 12 : isTablet ? 6 : 12}
            md={6}
            direction='column'
            container
            justifyContent={isMobile ? 'flex-start' : 'center'}
            alignItems={isMobile ? 'center' : isTablet ? 'center' : 'center'}
            marginLeft={isMobile ? '' : isTablet ? '-65px' : '-200px'}
          >
            <Grid
              sx={{ width: isMobile ? 320 : isTablet ? 400 : 700, height: isMobile ? 750 : 500 }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant={isMobile ? 'h6' : 'h5'}
                  component='div'
                  textAlign='center'
                >
                  {isDeutsch ? infoSection2.title : infoSection2.titleEn}
                  <br />
                  <br />
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle2' : isTablet ? 'body1' : 'h6'}
                  color='text.secondary'
                  textAlign='left'
                >
                  {isDeutsch ? infoSection2.textPart1 : infoSection2.textPart1En}
                  <br />
                  <br />
                  {isDeutsch ? infoSection2.textPart2 : infoSection2.textPart2En}
                  &nbsp;
                  <span style={{ fontStyle: 'italic' }}>
                    {isDeutsch ? infoSection2.italicWords : infoSection2.italicWordsEn}
                  </span>
                  &nbsp;
                  {isDeutsch ? infoSection2.textPart3 : infoSection2.textPart3En}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};
export default InfoSection3;
