import React, { useEffect, useState, useRef } from 'react';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Marcel from '../../components/Pictures/Marcel.jpg';
import Marius from '../../components/Pictures/Marius.jpg';
import Kontakt from 'pages/InputForm/Kontakt';
import PrivacyText from 'pages/InputForm/PrivacyText';
import Impressum from 'pages/InputForm/Impressum';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

const AboutSection6 = () => {
  const [aboutSection6, setAboutSection6] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/about-section6?populate=image1,image2`)
      .then((response) => {
        console.log('Section1 data:', response.data);
        setAboutSection6(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section1 content:', error);
      });
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: aboutSection6.primaryColorLight || '#005B7F',
        main: aboutSection6.primaryColorMain || '#009374',
        dark: aboutSection6.primaryColorDark || '#005946',
        contrastText: aboutSection6.primaryContrastText || '#fff',
      },
      secondary: {
        light: aboutSection6.secondaryColorLight || '#0080b2',
        main: aboutSection6.secondaryColorMain || '#005B7F',
        dark: aboutSection6.secondaryColorDark || '#0080b2',
        contrastText: aboutSection6.secondaryContrastText || '#fff',
      },
    },
  });

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !animationPlayed) {
          setIsInView(true);
          setAnimationPlayed(true);
        } else {
          setIsInView(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const section = sectionRef.current;
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, [animationPlayed]);
  return (
    <ThemeProvider theme={theme}>
      <div style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
        <Grid marginBottom={isMobile ? '' : isTablet ? '' : '20px'}>
          <Typography
            gutterBottom
            variant={isMobile ? 'h6' : 'h2'}
            component='div'
            textAlign={isMobile ? 'center' : isTablet ? 'center' : 'center'}
            marginBottom={isMobile ? '' : isTablet ? '' : '50px'}
            marginTop={isMobile ? '' : isTablet ? '' : '200px'}
          >
            {isDeutsch ? aboutSection6.title : aboutSection6.titleEn}
            <br />
            <br />
          </Typography>
          <Grid
            container
            direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
            justifyContent='center'
            spacing={6}
            ref={sectionRef}
          >
            {/* <Slide direction='right' in={isMobile ? true : animationPlayed} timeout={1000}> */}
            <Grid item xs={12} sm={6}>
              <Grid
                container
                direction='row'
                justifyContent={isMobile ? 'center' : isTablet ? 'center' : 'flex-end'}
                alignItems='center'
                sx={{
                  '@media (max-width: 900px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '15px',
                  },
                }}
              >
                <Grid item xs={10} sm={4}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ marginInline: 'auto' }}>
                      <img
                        style={{
                          borderRadius: '50%',
                          width: isTablet ? '90%' : isMobile ? '80%' : '100%',
                          maxWidth: '250px',
                        }}
                        src={`${baseURL}${aboutSection6.image1?.data?.attributes?.url}`}
                        alt='Marcel Altendeitering'
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
                >
                  <Typography
                    variant={isMobile ? 'h6' : isTablet ? 'h5' : 'h4'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {aboutSection6.name1}
                  </Typography>
                  <Typography
                    variant={isMobile ? 'subtitle2' : isTablet ? 'h5' : 'h6'}
                    sx={{
                      '@media (max-width: 900px)': {
                        fontSize: '20px',
                      },
                      '@media (max-width: 600px)': {
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    {aboutSection6.email}&nbsp;&nbsp;
                    <a href={`mailto: ${aboutSection6.email1}`}>{aboutSection6.email1}</a>
                    <br />
                    {aboutSection6.telefon} &nbsp;&nbsp;&nbsp;&nbsp;{aboutSection6.tel1}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* </Slide> */}
            {/* <Slide direction='left' in={isMobile ? true : animationPlayed} timeout={1000}> */}
            <Grid item xs={12} sm={6}>
              <Grid
                container
                direction='row'
                justifyContent={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
                alignItems='center'
                sx={{
                  '@media (max-width: 900px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                  marginBottom: '50px', //margin added
                }}
              >
                <Grid item xs={10} sm={4}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ marginInline: 'auto' }}>
                      <img
                        style={{
                          borderRadius: '50%',
                          width: isTablet ? '90%' : isMobile ? '80%' : '100%',
                          maxWidth: '250px',
                        }}
                        src={`${baseURL}${aboutSection6.image2?.data?.attributes?.url}`}
                        alt='Marius Hupperz'
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
                  marginBottom={isMobile ? '55px' : isTablet ? '55px' : ''} //margin added
                >
                  <Typography
                    variant={isMobile ? 'h6' : isTablet ? 'h5' : 'h4'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {aboutSection6.name2}
                  </Typography>
                  <Typography
                    variant={isMobile ? 'subtitle2' : isTablet ? 'h5' : 'h6'}
                    sx={{
                      '@media (max-width: 900px)': {
                        fontSize: '20px',
                      },
                      '@media (max-width: 600px)': {
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    {aboutSection6.email}&nbsp;&nbsp;
                    <a href={`mailto: ${aboutSection6.email2}`}>{aboutSection6.email2}</a>
                    <br />
                    {aboutSection6.telefon} &nbsp;&nbsp;&nbsp;&nbsp;{aboutSection6.tel2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* </Slide> */}
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default AboutSection6;
