import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';
import { Menu } from '@mui/material';

export default function CheckBoxNineEn() {
  const { aufwandNine, setAufwandNine } = useMyContext();
  const { incomingDataFrom, setIncomingDataFrom } = useMyContext();
  const { incomingDataTo, setIncomingDataTo } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandNine);

  const handleUpdate = async (value: any, from: number, to: number) => {
    try {
      const updates = {
        aufwandNine: value,
        incomingDataFrom: from,
        incomingDataTo: to,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandNine(value); // Update local context/state immediately
    let from = 1;
    let to = 2;

    switch (value) {
      case 'bis 10 GB':
        from = 1;
        to = 10;
        break;
      case '11 bis 100 GB':
        from = 11;
        to = 100;
        break;
      case 'Unbegrenzt':
        from = 101;
        to = 1000;
        break;
      default:
        from = 0;
        to = 0;
    }

    setIncomingDataFrom(from);
    setIncomingDataTo(to);
    handleUpdate(value, from, to); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandNine) {
      setAufwandNine(selectedValue);
      setSelectedValue(aufwandNine);
      setIncomingDataFrom(incomingDataFrom);
      setIncomingDataTo(incomingDataTo);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Monthly data transfer volume
        </FormLabel>
        <Tooltip
          title='How much data do you want to transfer per month via your connector'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Choose an element</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Choose an element'
          onChange={handleChange}
        >
          <MenuItem value={'bis 10 GB'}>up to 10 GB</MenuItem>
          <MenuItem value={'11 bis 100 GB'}>10 up to 100 GB</MenuItem>
          <MenuItem value={'Unbegrenzt'}>Unlimited</MenuItem>
          <MenuItem value={'none'}>None</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
