import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  styled,
  ListItemButton,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link, useNavigate } from 'react-router-dom';
import TranslationButton from '../../components/TranslationButton';
import { useLanguage } from '../../LanguageContext';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import loginUser from 'loginUser';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoutUser from 'logoutUser';
import { useUser } from '../../UserContext';
import IndustrySelect from '../../components/IndustrySelect';
import CountrySelect from '../../components/CountrySelect';
import { useData } from '../../DataContext';
import { useMyContext } from '../../MyContext';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const { isDeutsch } = useLanguage();
  const location = useLocation();
  const [menuContent, setMenuContent] = useState<any>({});
  const [menuMedia, setMenuMedia] = useState<any>({});
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('jwt'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [companyZipcode, setCompanyZipcode] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [contactFirstname, setContactFirstname] = useState('');
  const [contactLastname, setContactLastname] = useState('');
  const { setUser } = useUser();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          isDeutsch ? `${baseURL}/api/item-list-deutsch` : `${baseURL}/api/item-list-englisch`,
        );
        console.log('Menu data:', response.data);
        setMenuContent(response.data.data.attributes);
      } catch (error) {
        console.error('Error fetching menu content:', error);
      }

      try {
        const response = await axios.get(
          `${baseURL}/api/menulogo?populate=menuLogo,menuLogoMobile`,
        );
        console.log('Menu media data:', response.data);
        setMenuMedia(response.data.data.attributes);
      } catch (error) {
        console.error('Error fetching header media:', error);
      }
    };

    fetchMenuData();
  }, [isDeutsch]);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getMenuItems = () => {
    const menuItems = [];

    let i = 1;
    while (menuContent[`page${i}`]) {
      const text = menuContent[`page${i}`];
      const to = menuContent[`path${i}`];
      menuItems.push({ text, to });
      i++;
    }

    return menuItems;
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: '#005B7F',
        main: '#009374',
        dark: '#005946',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0080b2',
        main: '#005B7F',
        dark: '#0080b2',
        contrastText: '#fff',
      },
    },
  });

  const itemListDeutsch = getMenuItems();
  const itemListEnglisch = getMenuItems();

  const handleLoginClick = () => {
    setIsRegisterMode(false);
    setIsLoginDialogOpen(true);
  };

  const handleLoginClose = () => {
    setIsLoginDialogOpen(false);
  };

  const {
    aufwandOne,
    aufwandTwo,
    aufwandThree,
    aufwandFour,
    aufwandFive,
    aufwandSix,
    aufwandSeven,
    industrySector,
    selectedCountry,
    contactEmail,
    companyItExpertsFrom,
    companyItExpertsTo,
  } = useMyContext();

  const { updateResponseData } = useData();

  const sendSelectedData = async () => {
    console.log('----------------------------------------------------');
    try {
      const backendUri = process.env.REACT_APP_BACKEND_URL;
      console.log('Backend URI:', backendUri);
      if (!backendUri) {
        throw new Error('Backend URI not defined in environment variables');
      }

      const requestBody: any = {
        companySize: aufwandOne,
        companyItExpertsFrom: companyItExpertsFrom,
        companyItExpertsTo: companyItExpertsTo,
        companyItKnowhow: aufwandThree,
        dataspaceRoles: aufwandFour,
        dataAvailabilities: aufwandFive,
        companyType: companyType,
        companyLocation: companyLocation,
        companyZipcode: companyZipcode,
      };

      if (aufwandSeven) {
        requestBody.serviceLevel = aufwandSeven;
      }

      if (aufwandSix) {
        requestBody.usagePolicies = aufwandSix;
      }

      if (industrySector) {
        requestBody.companyIndustrySectors = industrySector;
      }

      console.log('Request Body:', requestBody);

      const response = await fetch(backendUri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      console.log('Response Status:', response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Failed to send data to the backend. Server response:', errorText);
        throw new Error('Failed to send data to the backend');
      }

      const responseDataString = await response.text();
      const responseData = JSON.parse(responseDataString);
      updateResponseData(responseData);
      console.log('Response from backend:', responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLastRecommendationsClick = async () => {
    await sendSelectedData();
    navigate('/app', { state: { skipSteps: true } });
  };

  const handleLoginSubmit = async () => {
    try {
      const data = await loginUser(
        email,
        password,
        isRegisterMode,
        companyName,
        companyType,
        companyZipcode,
        companyLocation,
        selectedCountry,
        contactFirstname,
        contactLastname,
        industrySector, // Pass industrySector
      );

      if (isRegisterMode && data.message) {
        alert(data.message);
      } else {
        localStorage.setItem('jwt', data.jwt);
        setUser(data.user);
        setIsLoginDialogOpen(false);
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      console.error('Login/Registration failed:', error);
    }
  };

  const handleLogout = () => {
    logoutUser();
    localStorage.removeItem('jwt');
    setIsLoggedIn(false);
    handleMenuClose();
    navigate('/');
    window.location.reload();
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        component='nav'
        position='sticky'
        sx={{
          background: menuMedia.menubarColor || '#fff',
          opacity: '0.9',
          maxWidth: '2000px',
          overflow: 'hidden',
          margin: '0 auto',
        }}
        elevation={0}
        style={{ boxShadow: '0 0 10px 0' }}
      >
        <StyledToolbar>
          {isMobileView ? (
            <img
              src={`${baseURL}${menuMedia.menuLogoMobile?.data?.attributes?.url}`}
              alt='MDSxNRW_Mobile_Logo'
              style={{
                width: '10%',
              }}
            />
          ) : (
            <img
              src={`${baseURL}${menuMedia.menuLogo?.data?.attributes?.url}`}
              alt='MDSxNRW_PC_Logo'
              style={{
                width: '12%',
              }}
            />
          )}
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <DrawerItem />
          </Box>
          <ListMenu>
            {isDeutsch
              ? itemListDeutsch.map((item) => {
                  const { text, to } = item;
                  return (
                    <ListItem key={text}>
                      <ListItemButton
                        component={Link}
                        to={to}
                        sx={{
                          color:
                            location.pathname === to
                              ? menuMedia.menuNameColorActive || '#11998E'
                              : menuMedia.menuNameColorNotActive || '#000',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#1e2a5a',
                          },
                        }}
                      >
                        <ListItemText primary={text} sx={{ whiteSpace: 'nowrap' }} />
                      </ListItemButton>
                    </ListItem>
                  );
                })
              : itemListEnglisch.map((item) => {
                  const { text, to } = item;
                  return (
                    <ListItem key={text}>
                      <ListItemButton
                        component={Link}
                        to={to}
                        sx={{
                          color:
                            location.pathname === to
                              ? menuMedia.menuNameColorActive || '#11998E'
                              : menuMedia.menuNameColorNotActive || '#000',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: '#1e2a5a',
                          },
                        }}
                      >
                        <ListItemText primary={text} sx={{ whiteSpace: 'nowrap' }} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </ListMenu>
          {/* {isLoggedIn ? (
            <>
              <Button sx={{ color: '#000' }} onClick={handleMenuClick}>
                {isDeutsch ? 'Profil' : 'Profile'}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: '5px' }}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/profile');
                    handleMenuClose();
                  }}
                >
                  {isDeutsch ? 'Mein Profil' : 'My Profile'}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    handleLastRecommendationsClick();
                  }}
                >
                  {isDeutsch ? 'Letzte Empfehlungen' : 'Last Recommendations'}
                </MenuItem>
                <MenuItem onClick={handleLogout}>{isDeutsch ? 'Abmelden' : 'Logout'}</MenuItem>
              </Menu>
            </>
          ) : (
            <Button sx={{ color: '#000' }} onClick={handleLoginClick}>
              {isDeutsch ? 'Anmelden' : 'Login'}
            </Button>
          )}
          <Dialog open={isLoginDialogOpen} onClose={handleLoginClose} sx={{ color: '#009374' }}>
            <DialogTitle>
              {isRegisterMode
                ? isDeutsch
                  ? 'Konto Erstellen'
                  : 'Register'
                : isDeutsch
                ? 'Anmelden'
                : 'Login'}
            </DialogTitle>
            <DialogContent sx={{ color: '#009374' }}>
              <DialogContentText>
                {isRegisterMode
                  ? isDeutsch
                    ? 'Zur Registrierung geben Sie hier bitte Ihre Daten ein.'
                    : 'To register, please enter your details here.'
                  : isDeutsch
                  ? 'Um auf diese Seite zuzugreifen, geben Sie hier bitte Ihre E-Mail-Adresse und Ihr Passwort ein.'
                  : 'To access this page, please enter your email address and password here.'}
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='email'
                label={isDeutsch ? 'Email Addresse' : 'Email Address'}
                type='email'
                fullWidth
                variant='standard'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin='dense'
                id='password'
                label={isDeutsch ? 'Passwort' : 'Password'}
                type='password'
                fullWidth
                variant='standard'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isRegisterMode ? (
                <>
                  <TextField
                    margin='dense'
                    id='companyName'
                    label={isDeutsch ? 'Name des Untermenhmen' : 'Company Name'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <TextField
                    margin='dense'
                    id='companyType'
                    label={isDeutsch ? 'Gesellschaftsform' : 'Company Type'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={companyType}
                    onChange={(e) => setCompanyType(e.target.value)}
                  />
                  <IndustrySelect />
                  <TextField
                    margin='dense'
                    id='CompanyZipcode'
                    label={isDeutsch ? 'Postleitzahl' : 'Company Zipcode'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={companyZipcode}
                    onChange={(e) => setCompanyZipcode(e.target.value)}
                  />
                  <TextField
                    margin='dense'
                    id='companyLocation'
                    label={isDeutsch ? 'Standort' : 'Company Location'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={companyLocation}
                    onChange={(e) => setCompanyLocation(e.target.value)}
                  />
                  <CountrySelect />
                  <TextField
                    margin='dense'
                    id='firstName'
                    label={isDeutsch ? 'Vorname' : 'First Name'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={contactFirstname}
                    onChange={(e) => setContactFirstname(e.target.value)}
                  />
                  <TextField
                    margin='dense'
                    id='lastName'
                    label={isDeutsch ? 'Nachname' : 'Last Name'}
                    type='text'
                    fullWidth
                    variant='standard'
                    value={contactLastname}
                    onChange={(e) => setContactLastname(e.target.value)}
                  />
                </>
              ) : null}
              <DialogContentText>
                {isRegisterMode
                  ? isDeutsch
                    ? 'Sie haben bereits ein Konto?'
                    : 'Already have an account?'
                  : isDeutsch
                  ? 'Sie haben noch kein Konto?'
                  : 'Do not have an account yet?'}
                <Typography
                  variant='body2'
                  onClick={() => setIsRegisterMode(!isRegisterMode)}
                  sx={{ marginLeft: '5px', cursor: 'pointer', display: 'inline', color: '#009374' }}
                >
                  {isRegisterMode
                    ? isDeutsch
                      ? 'Anmelden'
                      : 'Login'
                    : isDeutsch
                    ? 'Konto Erstellen'
                    : 'Register'}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLoginClose} sx={{ color: '#009374' }}>
                {isDeutsch ? 'Abbrechen' : 'Cancel'}
              </Button>
              <Button onClick={handleLoginSubmit} sx={{ color: '#009374' }}>
                {isRegisterMode
                  ? isDeutsch
                    ? 'Konto Erstellen'
                    : 'Register'
                  : isDeutsch
                  ? 'Anmelden'
                  : 'Login'}
              </Button>
            </DialogActions>
          </Dialog> */}
          {isMobile ? '' : <TranslationButton />}
        </StyledToolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
