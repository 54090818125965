import React, { useState } from 'react';
import BlogContent from './BlogContent';

function BlogPage() {
  return (
    <div id='blog' className='container'>
      <BlogContent />
    </div>
  );
}

export default BlogPage;
