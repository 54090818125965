import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Impressum from './Impressum';
import Kontakt from './Kontakt';
import PrivacyText from './PrivacyText';
import DataVisualizationThree from '../../components/Pictures/DataVisualizationThree.png';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

const ImpressumText = () => {
  const [imprint, setImprint] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/imprint?populate=background`)
      .then((response) => {
        console.log('Section1 data:', response.data);
        setImprint(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section1 content:', error);
      });
  }, []);

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Grid id='impressum' style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
      <header style={{ width: '100%', height: '100%', position: 'relative' }}>
        <img
          src={`${baseURL}${imprint.background?.data?.attributes?.url}`}
          alt='DataVisualization'
          width='100%'
          height='100%'
          style={{ marginTop: '-28%' }}
        />
        <Grid
          container
          spacing={2}
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          marginLeft='12%'
          marginTop={isMobile ? '-2%' : isTablet ? '-5%' : '-10%'}
          marginBottom={isMobile ? '20px' : '40px'}
        >
          <Typography variant={isMobile ? 'subtitle1' : isTablet ? 'h5' : 'h4'}>
            <h1 style={{ color: imprint.titleColor }}>
              {isDeutsch ? imprint.title : imprint.titleEn}
            </h1>
          </Typography>
        </Grid>
      </header>
      <br />
      <body>
        <Typography
          variant='body1'
          style={{
            width: '76%',
            marginLeft: '12%',
            marginRight: '12%',
            textAlign: 'justify',
            textJustify: 'inter-word',
          }}
        >
          {isDeutsch ? imprint.textPart1 : imprint.textPart1En}
          <br />
          {imprint.internet}
          <br />
          {imprint.emailText}&nbsp; <a href={`mailto: ${imprint.email}`}>{imprint.email}</a>
          <br />
          <p>
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {isDeutsch ? imprint.redakteur : imprint.redakteurEn}
            </Typography>
            <br />
            {isDeutsch ? imprint.redakteurText : imprint.redakteurTextEn}
            <br />
          </p>
          <p>
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {isDeutsch ? imprint.vorstand : imprint.vorstandEn}
            </Typography>
            <br />
            {isDeutsch ? imprint.vorstandName1 : imprint.vorstandName1En}
            <br />
            {isDeutsch ? imprint.vorstandName2 : imprint.vorstandName2En}
          </p>
          <p>
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {isDeutsch ? imprint.nutzungsrechte : imprint.nutzungsrechteEn}
            </Typography>
            <br />
            {isDeutsch ? imprint.nutzungsrechteText1 : imprint.nutzungsrechteText1En}
            <br />
            {isDeutsch ? imprint.anfragen : imprint.anfragenEn}
            <br />
            {isDeutsch ? imprint.anfragenCompany : imprint.anfragenCompanyEn}
            <br />
            {imprint.anfragenAdress}
            <br />
            {isDeutsch ? imprint.nutzungsrechteText2 : imprint.nutzungsrechteText2En}
            <br />
            {isDeutsch ? imprint.nutzungsrechteText3 : imprint.nutzungsrechteText3En}
          </p>
          <Typography variant={isMobile ? 'h5' : 'h4'}>
            {isDeutsch ? imprint.haftungshinweis : imprint.haftungshinweisEn}
          </Typography>
          <br />
          {isDeutsch ? imprint.haftungshinweisText : imprint.haftungshinweisTextEn}
          <br />
        </Typography>
      </body>
    </Grid>
  );
};

export default ImpressumText;
