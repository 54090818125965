import React, { ReactNode, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Impressum from 'pages/InputForm/Impressum';
import PrivacyText from 'pages/InputForm/PrivacyText';
import Kontakt from 'pages/InputForm/Kontakt';
import axios from 'axios';

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <div style={{ flex: 1 }}>{children}</div>
      <Footer />
    </div>
  );
};

const Footer = () => {
  const [copyright, setCopyright] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/footer`)
      .then((response) => {
        console.log('Copyright data:', response.data);
        setCopyright(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching copyright content:', error);
      });
  }, []);

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        '@media (max-width: 600px)': {
          marginTop: '480px !important',
        },
        '@media (max-width: 1100px)': {
          marginTop: '150px !important',
        },
        '@media (max-width: 1535px)': {
          marginTop: '200px',
        },
        '@media (min-width: 1536px)': {
          marginTop: '160px',
        },
      }}
      maxWidth='2000px'
      overflow='hidden'
      marginBottom='20px'
    >
      <Typography variant='body2' color='text.secondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' target='_blank' href='https://www.isst.fraunhofer.de/'>
          {copyright.copyright}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'} <br />
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '20px',
            gap: '8rem',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
            },
          }}
        >
          <Impressum />
          <PrivacyText />
          <Kontakt />
        </Typography>
      </Typography>
    </Grid>
  );
};

export default PageLayout;
