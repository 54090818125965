import axios from 'axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;
const updateUser = async (jwt, updates) => {
  try {
    const response = await axios.get(`${baseURL}/api/users/me`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    const userId = response.data.id; // Assuming the response includes the user ID

    // Update user endpoint using fetched userId
    const updateResponse = await axios.put(`${baseURL}/api/users/${userId}`, updates, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    console.log('User content updated successfully:', updateResponse.data);

    return updateResponse.data; // Return updated user data
  } catch (error) {
    console.error(
      'Error updating user content:',
      error.response ? error.response.data : error.message,
    );
    throw new Error(error.response ? error.response.data.error.message : error.message);
  }
};

export default updateUser;
