import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';

export default function BlogContent() {
  const { isDeutsch } = useLanguage();
  const theme = createTheme({
    palette: {
      primary: {
        light: '#005B7F',
        main: '#009374',
        dark: '#005946',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0080b2',
        main: '#005B7F',
        dark: '#0080b2',
        contrastText: '#fff',
      },
    },
  });

  const [blogContent, setBlogContent] = useState<any[]>([]);
  const [blogMedia, setBlogMedia] = useState<any[]>([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/blogs?populate=blogMedia`)
      .then((response) => {
        console.log('Blog data:', response.data);
        setBlogContent(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching blog content:', error);
      });
  }, []);

  /* useEffect(() => {
    axios
      .get('http://localhost:1337/api/upload/files')
      .then((response) => {
        console.log('Blog media data:', response.data);
        setBlogMedia(response.data);
      })
      .catch((error) => {
        console.error('Error fetching blog media:', error);
      });
  }, []); */

  const isMobile = window.innerWidth < 600;
  const isTablet = window.innerWidth < 1600;

  return (
    <ThemeProvider theme={theme}>
      <Fade in={true} timeout={2500}>
        <div style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
          <Grid
            container
            spacing={8}
            display='flex'
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent='center'
            alignContent='center'
          >
            <Grid
              item
              direction='column'
              container
              justifyContent='flex-start'
              alignItems={isMobile ? 'center' : isTablet ? 'center' : 'center'}
            >
              <Typography variant={isMobile ? 'h5' : 'h4'}>
                <h1
                  style={{
                    color: '#005B7F',
                    marginTop: isMobile ? '-130px' : isTablet ? '-40px' : '100px',
                  }}
                >
                  {isDeutsch ? 'Unser Blog für' : 'Our Blog for'}
                </h1>
              </Typography>
              <Box
                sx={{
                  backgroundImage: 'url(./MDSxNRWLogo1.svg)',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: isMobile ? 300 : isTablet ? 350 : 530,
                  height: isMobile ? '80px' : isTablet ? '120px' : '140px',
                  width: isMobile ? '300px' : isTablet ? '350px' : '530px',
                  marginTop: isMobile ? '-130px' : isTablet ? '-40px' : '0px',
                }}
              ></Box>
            </Grid>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              marginTop={isMobile ? '-100px' : ''}
            >
              {blogContent.length > 0 ? (
                blogContent.map((post, index) => (
                  <Grid
                    key={post.id}
                    container
                    direction={index % 2 === 0 ? 'row' : 'row-reverse'}
                    justifyContent='center'
                    alignItems='center'
                    sx={{ padding: '100px' }}
                  >
                    <Grid
                      item
                      container
                      direction='column'
                      justifyContent='center'
                      alignItems={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
                      xs={12}
                      md={6}
                      sx={{ paddingRight: isMobile ? 0 : isTablet ? '20px' : '40px' }}
                    >
                      <Typography variant='h6' gutterBottom>
                        {post.attributes.blogTitle}
                      </Typography>
                      {post.attributes.blogText.map((text: any, textIndex: number) => (
                        <p key={textIndex}>{text.children[textIndex].text}</p>
                      ))}
                    </Grid>
                    <Grid
                      item
                      container
                      direction='column'
                      justifyContent='center'
                      alignItems='center'
                      xs={12}
                      md={6}
                      sx={{ paddingLeft: isMobile ? 0 : isTablet ? '20px' : '40px' }}
                    >
                      <img
                        src={`${baseURL}${post.attributes.blogMedia.data.attributes.url}`}
                        alt={'Blog Foto'}
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                      <Typography variant='body2' sx={{ color: '#666', textAlign: 'center' }}>
                        © {post.attributes.imageSource}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </Grid>
          </Grid>
        </div>
      </Fade>
    </ThemeProvider>
  );
}
