import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../UserContext';
import updateUser from '../../updateUser';
import { useLanguage } from '../../LanguageContext';

const Profile = () => {
  const { user, setUser } = useUser();
  const [userId, setUserId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { isDeutsch } = useLanguage();
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await axios.get(`${baseURL}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        setUserId(response.data.id);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchUserId();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: '#005B7F',
        main: '#009374',
        dark: '#005946',
        contrastText: '#fff',
      },
      secondary: {
        light: '#0080b2',
        main: '#005B7F',
        dark: '#0080b2',
        contrastText: '#fff',
      },
    },
  });

  const handleDelete = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      await axios.delete(`${baseURL}/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setUser(null);
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    navigate('/');
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 4 }}>
        <Typography variant='h4' gutterBottom>
          {isDeutsch ? 'Mein Profil' : 'My Profile'}
        </Typography>
        <Box component='form' noValidate autoComplete='off' maxWidth='500px'>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={4}>
              <Typography>Email:</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                margin='normal'
                value={user?.email || ''}
                InputProps={{
                  readOnly: true,
                }}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>{isDeutsch ? 'Vorname' : 'First Name:'}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                margin='normal'
                value={user?.contactFirstname || ''}
                InputProps={{
                  readOnly: true,
                }}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>{isDeutsch ? 'Nachname' : 'Last Name:'}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                margin='normal'
                value={user?.contactLastname || ''}
                InputProps={{
                  readOnly: true,
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            onClick={handleDelete}
            sx={{
              mt: 2,
              backgroundColor: '#949B9E',
              '&:hover': {
                backgroundColor: 'darkred',
              },
              '&:active': {
                backgroundColor: 'darkred',
              },
            }}
          >
            {isDeutsch ? 'Konto löschen' : 'Delete Profile'}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {isDeutsch ? 'Konto gelöscht' : 'Account Deleted'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {isDeutsch
              ? 'Ihr Konto wurde erfolgreich gelöscht.'
              : 'Your account has been successfully deleted.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            {isDeutsch ? 'Schließen' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Profile;
