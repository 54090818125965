import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Page.module.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import { useLanguage } from '../../LanguageContext';
import axios from 'axios';
import loginUser from 'loginUser';
import logoutUser from 'logoutUser';
import { useUser } from '../../UserContext';
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material';
import IndustrySelect from '../../components/IndustrySelect';
import CountrySelect from '../../components/CountrySelect';
import { useMyContext } from '../../MyContext';

const Header = () => {
  const { isDeutsch } = useLanguage();

  const [headerContent, setHeaderContent] = useState<any>({});
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('jwt'));
  const [menuContent, setMenuContent] = useState<any>({});
  const [menuMedia, setMenuMedia] = useState<any>({});
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [companyZipcode, setCompanyZipcode] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [contactFirstname, setContactFirstname] = useState('');
  const [contactLastname, setContactLastname] = useState('');
  const { industrySector, selectedCountry } = useMyContext();
  const { setUser } = useUser();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    axios
      .get(`${baseURL}/api/header?populate=lpImage,lpLogoOne,lpLogoTwo,lpLogoThree`)
      .then((response) => {
        console.log('Header data:', response.data);
        setHeaderContent(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching header content:', error);
      });
  }, []);

  const handleLoginClick = () => {
    setIsRegisterMode(false); // Ensure login mode is set when clicking the login button
    setIsLoginDialogOpen(true);
  };

  const handleLoginClose = () => {
    setIsLoginDialogOpen(false);
  };

  const handleLoginSubmit = async () => {
    try {
      const data = await loginUser(
        email,
        password,
        isRegisterMode,
        companyName,
        companyType,
        companyZipcode,
        companyLocation,
        selectedCountry,
        contactFirstname,
        contactLastname,
        industrySector,
      );

      // Update user state after successful login or registration
      localStorage.setItem('jwt', data.jwt);
      setUser(data.user); // This sets the user data in UserContext

      setIsLoginDialogOpen(false);
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Login/Registration failed:', error);
    }
  };

  const handleLogout = () => {
    logoutUser();
    localStorage.removeItem('jwt');
    setIsLoggedIn(false);
    handleMenuClose();
    navigate('/');
    window.location.reload();
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: headerContent.primaryColorLight || '#005B7F',
        main: headerContent.primaryColorMain || '#009374',
        dark: headerContent.primaryColorDark || '#005946',
        contrastText: headerContent.primaryContrastText || '#fff',
      },
      secondary: {
        light: headerContent.secondaryColorLight || '#0080b2',
        main: headerContent.secondaryColorMain || '#005B7F',
        dark: headerContent.secondaryColorDark || '#0080b2',
        contrastText: headerContent.secondaryContrastText || '#fff',
      },
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
      setIsTablet(window.innerWidth < 1100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
        <Grid
          container
          spacing={1}
          display='flex'
          flexDirection={isMobile ? 'column' : isTablet ? 'row' : 'row'}
          justifyContent='center'
          alignItems='center'
          maxWidth='2000px'
        >
          <Grid
            item
            xs={isMobile ? 12 : isTablet ? 7 : 12}
            md={6}
            container
            direction='column'
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems='center'
            paddingRight={isMobile ? 0 : isTablet ? 0 : '30px'}
          >
            <Slide direction='right' in={true} timeout={1000}>
              <Grid>
                <Typography
                  variant={isMobile ? 'h5' : isTablet ? 'h4' : 'h4'}
                  component='h1'
                  sx={{
                    fontWeight: 700,
                    color: headerContent.titleTextColor,
                    lineHeight: '1.1',
                    mt: '100px',
                    mb: '20px',
                    maxWidth: '500px',
                    /* whiteSpace: 'nowrap', */
                  }}
                >
                  {isDeutsch ? headerContent.lpTitle : headerContent.lpTitleEnglish}
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle2' : isTablet ? 'body2' : 'body1'}
                  color='text.secondary'
                  sx={{
                    maxWidth: '500px',
                  }}
                >
                  {isDeutsch ? headerContent.lpSubTitle : headerContent.lpSubTitleEnglish}
                </Typography>
                <Typography sx={{ display: 'flex', marginTop: isMobile ? '' : '30px' }}>
                  {/* {!isLoggedIn ? (
                    <>
                      <Button
                        onClick={handleLoginClick}
                        variant='contained'
                        sx={{
                          mt: '18px',
                          width: isMobile ? '130px' : isTablet ? '190px' : '230px',
                          height: '60px',
                          textTransform: 'none',
                          color: 'primary',
                          '&&:focus': {
                            backgroundColor: '#009374',
                          },
                        }}
                      >
                        <Typography
                          component='body'
                          variant={isMobile ? 'body2' : isTablet ? 'h6' : 'h5'}
                          sx={{
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                          }}
                        >
                          {isDeutsch
                            ? headerContent.firstButtonText
                            : headerContent.firstButtonTextEnglish}
                        </Typography>
                      </Button>
                      <Dialog
                        open={isLoginDialogOpen}
                        onClose={handleLoginClose}
                        sx={{ color: '#009374' }}
                      >
                        <DialogTitle>
                          {isRegisterMode
                            ? isDeutsch
                              ? 'Konto Erstellen'
                              : 'Register'
                            : isDeutsch
                            ? 'Anmelden'
                            : 'Login'}
                        </DialogTitle>
                        <DialogContent sx={{ color: '#009374' }}>
                          <DialogContentText>
                            {isRegisterMode
                              ? isDeutsch
                                ? 'Zur Registrierung geben Sie hier bitte Ihre Daten ein.'
                                : 'To register, please enter your details here.'
                              : isDeutsch
                              ? 'Um auf diese Seite zuzugreifen, geben Sie hier bitte Ihre E-Mail-Adresse und Ihr Passwort ein.'
                              : 'To access this page, please enter your email address and password here.'}
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin='dense'
                            id='email'
                            label={isDeutsch ? 'Email Addresse' : 'Email Address'}
                            type='email'
                            fullWidth
                            variant='standard'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <TextField
                            margin='dense'
                            id='password'
                            label={isDeutsch ? 'Passwort' : 'Password'}
                            type='password'
                            fullWidth
                            variant='standard'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {isRegisterMode ? (
                            <>
                              <TextField
                                margin='dense'
                                id='companyName'
                                label={isDeutsch ? 'Name des Untermenhmen' : 'Company Name'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                              />
                              <TextField
                                margin='dense'
                                id='companyType'
                                label={isDeutsch ? 'Gesellschaftsform' : 'Company Type'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={companyType}
                                onChange={(e) => setCompanyType(e.target.value)}
                              />
                              <IndustrySelect />
                              <TextField
                                margin='dense'
                                id='CompanyZipcode'
                                label={isDeutsch ? 'Postleitzahl' : 'Company Zipcode'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={companyZipcode}
                                onChange={(e) => setCompanyZipcode(e.target.value)}
                              />
                              <TextField
                                margin='dense'
                                id='companyLocation'
                                label={isDeutsch ? 'Standort' : 'Company Location'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={companyLocation}
                                onChange={(e) => setCompanyLocation(e.target.value)}
                              />
                              <CountrySelect />
                              <TextField
                                margin='dense'
                                id='firstName'
                                label={isDeutsch ? 'Vorname' : 'First Name'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={contactFirstname}
                                onChange={(e) => setContactFirstname(e.target.value)}
                              />
                              <TextField
                                margin='dense'
                                id='lastName'
                                label={isDeutsch ? 'Nachname' : 'Last Name'}
                                type='text'
                                fullWidth
                                variant='standard'
                                value={contactLastname}
                                onChange={(e) => setContactLastname(e.target.value)}
                              />
                            </>
                          ) : null}
                          <DialogContentText>
                            {isRegisterMode
                              ? isDeutsch
                                ? 'Sie haben bereits ein Konto?'
                                : 'Already have an account?'
                              : isDeutsch
                              ? 'Sie haben noch kein Konto?'
                              : 'Do not have an account yet?'}
                            <Typography
                              variant='body2'
                              onClick={() => setIsRegisterMode(!isRegisterMode)}
                              sx={{
                                marginLeft: '5px',
                                cursor: 'pointer',
                                display: 'inline',
                                color: '#009374',
                              }}
                            >
                              {isRegisterMode
                                ? isDeutsch
                                  ? 'Anmelden'
                                  : 'Login'
                                : isDeutsch
                                ? 'Konto Erstellen'
                                : 'Register'}
                            </Typography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleLoginClose} sx={{ color: '#009374' }}>
                            {isDeutsch ? 'Abbrechen' : 'Cancel'}
                          </Button>
                          <Button onClick={handleLoginSubmit} sx={{ color: '#009374' }}>
                            {isRegisterMode
                              ? isDeutsch
                                ? 'Konto Erstellen'
                                : 'Register'
                              : isDeutsch
                              ? 'Anmelden'
                              : 'Login'}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  ) : ( */}
                  <Button
                    component={Link}
                    to='/app'
                    variant='contained'
                    sx={{
                      mt: '18px',
                      width: isMobile ? '130px' : isTablet ? '190px' : '230px',
                      height: '60px',
                      textTransform: 'none',
                      color: 'primary',
                      '&&:focus': {
                        backgroundColor: '#009374',
                      },
                    }}
                  >
                    <Typography
                      component='body'
                      variant={isMobile ? 'body2' : isTablet ? 'h6' : 'h5'}
                      sx={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {isDeutsch
                        ? headerContent.firstButtonText
                        : headerContent.firstButtonTextEnglish}
                    </Typography>
                  </Button>
                  <Button
                    component={Link}
                    to='/about'
                    variant='outlined'
                    sx={{
                      ml: '30px',
                      mt: '18px',
                      width: isMobile ? '130px' : isTablet ? '190px' : '230px',
                      height: '60px',
                      textTransform: 'none',
                      color: '#primary',
                      '&&:focus': {
                        backgroundColor: '#009374',
                      },
                    }}
                  >
                    <Typography
                      component='body'
                      variant={isMobile ? 'body2' : isTablet ? 'h6' : 'h5'}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {isDeutsch
                        ? headerContent.secondButtonText
                        : headerContent.secondButtonTextEnglish}
                    </Typography>
                  </Button>
                </Typography>
              </Grid>
            </Slide>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : isTablet ? 5 : 12}
            md={5}
            container
            display='flex'
            direction='row'
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems='flex-end'
          >
            <Slide direction='left' in={true} timeout={1000}>
              <Box
                sx={{
                  backgroundImage: `url(${baseURL}${headerContent.lpImage?.data?.attributes?.url})`,
                  backgroundPosition: isMobile ? 'center' : isTablet ? 'bottom' : 'bottom',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: isMobile ? 250 : isTablet ? 300 : 500, //550
                  height: isTablet ? '500px' : '700px',
                  width: isMobile ? '90%' : isTablet ? '600px' : '600px',
                  '@media (max-width: 600px)': {
                    marginTop: '-200px',
                  },
                  '@media (max-width: 1100px)': {
                    marginTop: isMobile ? '-50px' : '',
                  },
                }}
              ></Box>
            </Slide>
          </Grid>
          <Slide direction='up' in={true} timeout={1000}>
            <Grid
              item
              xs={isMobile ? 12 : isTablet ? 5 : 12}
              md={6}
              display='flex'
              marginTop={isMobile ? '-100px' : '10px'}
              justifyContent='space-around'
              flexDirection={isMobile ? 'row' : 'row'}
              alignItems='center'
            >
              <img
                style={{
                  maxWidth: isMobile ? '100px' : '170px',
                  paddingRight: '20px',
                }}
                src={`${baseURL}${headerContent.lpLogoOne?.data?.attributes?.url}`}
                alt='mds'
              />
              <img
                style={{
                  maxWidth: isMobile ? '100px' : '160px',
                  paddingRight: '10px',
                }}
                src={`${baseURL}${headerContent.lpLogoTwo?.data?.attributes?.url}`}
                alt='mds'
              />
              <img
                style={{
                  maxWidth: isMobile ? '130px' : '220px',
                }}
                src={`${baseURL}${headerContent.lpLogoThree?.data?.attributes?.url}`}
                alt='mds'
              />
            </Grid>
          </Slide>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default Header;
