import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useLanguage } from '../LanguageContext';
import LanguageIcon from '@mui/icons-material/Language';
import axios from 'axios';

export default function BasicButtons() {
  const { isDeutsch, toggleLanguage } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [language, setLanguage] = useState<any>({});

  useEffect(() => {
    axios
      .get(`${baseURL}/api/language`)
      .then((response) => {
        console.log('Language data:', response.data);
        setLanguage(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching language content:', error);
      });
  }, []);

  return (
    <Stack spacing={2} direction='row'>
      <Button
        variant='text'
        onClick={toggleLanguage}
        sx={{
          color: '#000',
          marginLeft: '-10px',
        }}
      >
        <LanguageIcon
          sx={{
            color: isMobile
              ? language.languageColorMobile || '#414141'
              : language.languageColor || '#000',
          }}
        />
        <div
          style={{
            marginLeft: isMobile ? '32px' : '5px',
            color: isMobile
              ? language.languageColorMobile || '#414141'
              : language.languageColor || '#000',
          }}
        >
          {isDeutsch ? language.english : language.german}
        </div>
      </Button>
    </Stack>
  );
}
