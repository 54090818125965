import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMyContext } from '../MyContext';
import { useState, useEffect } from 'react';
import { useUser } from '../UserContext';
import updateUser from '../updateUser';

export default function CheckBoxTwo() {
  const { aufwandTwo, setAufwandTwo } = useMyContext();
  const { companyItExpertsFrom, setCompanyItExpertsFrom } = useMyContext();
  const { companyItExpertsTo, setCompanyItExpertsTo } = useMyContext();
  const { user, setUser } = useUser();

  const [selectedValue, setSelectedValue] = useState(aufwandTwo);

  const handleUpdate = async (value: any, from: number, to: number) => {
    try {
      const updates = {
        aufwandTwo: value,
        companyItExpertsFrom: from,
        companyItExpertsTo: to,
      };
      const updatedUser = await updateUser(localStorage.getItem('jwt'), updates);
      setUser(updatedUser);
      console.log('User updated successfully:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedValue(value);
    setAufwandTwo(value); // Update local context/state immediately
    let from = 0;
    let to = 10;

    switch (value) {
      case '<10':
        from = 0;
        to = 10;
        break;
      case '11-49':
        from = 11;
        to = 49;
        break;
      case '50-249':
        from = 50;
        to = 249;
        break;
      case '249<':
        from = 250;
        to = 1000;
        break;
      default:
        from = 0;
        to = 10;
    }

    setCompanyItExpertsFrom(from);
    setCompanyItExpertsTo(to);
    handleUpdate(value, from, to); // Trigger API update with the necessary values
  };

  useEffect(() => {
    if (!aufwandTwo) {
      setAufwandTwo(selectedValue);
      setSelectedValue(aufwandTwo);
      setCompanyItExpertsFrom(companyItExpertsFrom);
      setCompanyItExpertsTo(companyItExpertsTo);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid' }}>
      <FormControl
        sx={{ m: 0.5, minWidth: 250 }}
        style={{ display: 'inline-flex', alignItems: 'flex-start', flexDirection: 'row' }}
      >
        <FormLabel required component='legend'>
          Beschäftigte IT-Experten
        </FormLabel>
        <Tooltip
          title='Bitte geben Sie an, wie viele IT-Experten bei Ihnen beschäftigt sind.'
          placement='top-start'
          style={{ position: 'absolute', right: 0 }}
        >
          <InfoOutlinedIcon color='disabled' fontSize='small' />
        </Tooltip>
      </FormControl>
      <FormControl sx={{ m: 0.5, minWidth: 250 }}>
        <InputLabel id='element'>Wählen Sie ein Element aus</InputLabel>
        <Select
          labelId='element'
          id='someelement'
          value={selectedValue}
          label='Wählen Sie ein Element aus'
          onChange={handleChange}
        >
          <MenuItem value={'<10'}>bis 10 Beschäftigte</MenuItem>
          <MenuItem value={'11-49'}>11-49 Beschäftigte</MenuItem>
          <MenuItem value={'50-249'}>50-249 Beschäftigte</MenuItem>
          <MenuItem value={'249<'}>mehr als 249 Beschäftigte</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
