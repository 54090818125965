import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Impressum from './Impressum';
import Kontakt from './Kontakt';
import PrivacyText from './PrivacyText';
import Grid from '@mui/material/Grid';
import Marcel from '../../components/Pictures/Marcel.jpg';
import Marius from '../../components/Pictures/Marius.jpg';
import DataVisualizationThree from '../../components/Pictures/DataVisualizationThree.png';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import axios from 'axios';

const KontaktInfo = () => {
  const [contact, setContact] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/contact?populate=image1,image2,background`)
      .then((response) => {
        console.log('Section1 data:', response.data);
        setContact(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section1 content:', error);
      });
  }, []);

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1600);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Grid id='kontaktinfo' style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
      <header>
        <img
          src={`${baseURL}${contact.background?.data?.attributes?.url}`}
          alt='DataVisualization'
          width='100%'
          height='100%'
          style={{ marginTop: '-28%' }}
        />
        <Grid
          container
          spacing={2}
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          paddingLeft={isMobile ? '5%' : '10vw'}
          marginTop={isMobile ? '-10vw' : '-10%'}
          marginBottom={isMobile ? '20px' : '80px'}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              <h1 style={{ color: contact.titleColor }}>
                {isDeutsch ? contact.title : contact.titleEn}
              </h1>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={isMobile ? 'subtitle1' : 'h5'}>
              {isDeutsch ? contact.subTitle : contact.subTitleEn}
            </Typography>
          </Grid>
        </Grid>
      </header>
      <body>
        <Grid
          container
          direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
          justifyContent='center'
          spacing={6}
        >
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction='row'
              justifyContent={isMobile ? 'center' : isTablet ? 'center' : 'flex-end'}
              alignItems='center'
              sx={{
                '@media (max-width: 900px)': {
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '15px',
                },
              }}
            >
              <Grid item xs={10} sm={4}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ marginInline: 'auto' }}>
                    <img
                      style={{
                        borderRadius: '50%',
                        width: isTablet ? '90%' : isMobile ? '80%' : '100%',
                        maxWidth: '250px',
                      }}
                      src={`${baseURL}${contact.image1?.data?.attributes?.url}`}
                      alt='Marcel Altendeitering'
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction='column'
                justifyContent='center'
                alignItems={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
              >
                <Typography
                  variant={isMobile ? 'h6' : isTablet ? 'h5' : 'h4'}
                  style={{ fontWeight: 'bold' }}
                >
                  {contact.name1}
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle2' : isTablet ? 'h5' : 'h6'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '20px',
                    },
                    '@media (max-width: 600px)': {
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    },
                  }}
                >
                  {contact.email}&nbsp;&nbsp;
                  <a href={`mailto: ${contact.email1}`}>{contact.email1}</a>
                  <br />
                  {contact.telefon} &nbsp;&nbsp;&nbsp;&nbsp;{contact.tel1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction='row'
              justifyContent={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
              alignItems='center'
              sx={{
                '@media (max-width: 900px)': {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
                marginBottom: '50px', // 50px added
              }}
            >
              <Grid item xs={10} sm={4}>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ marginInline: 'auto' }}>
                    <img
                      style={{
                        borderRadius: '50%',
                        width: isTablet ? '90%' : isMobile ? '80%' : '100%',
                        maxWidth: '250px',
                      }}
                      src={`${baseURL}${contact.image2?.data?.attributes?.url}`}
                      alt='Marius Hupperz'
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction='column'
                justifyContent='center'
                alignItems={isMobile ? 'center' : isTablet ? 'center' : 'flex-start'}
                marginBottom={isMobile ? '55px' : isTablet ? '55px' : ''} //margin added
              >
                <Typography
                  variant={isMobile ? 'h6' : isTablet ? 'h5' : 'h4'}
                  style={{ fontWeight: 'bold' }}
                >
                  {contact.name2}
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle2' : isTablet ? 'h5' : 'h6'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '20px',
                    },
                    '@media (max-width: 600px)': {
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    },
                  }}
                >
                  {contact.email}&nbsp;&nbsp;
                  <a href={`mailto: ${contact.email2}`}>{contact.email2}</a>
                  <br />
                  {contact.telefon} &nbsp;&nbsp;&nbsp;&nbsp;{contact.tel2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </body>
    </Grid>
  );
};

export default KontaktInfo;
