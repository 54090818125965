import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import FilterOpenSource from 'components/FilterOpenSource';
import FilterServiceLevel from 'components/FilterServiceLevel';
import FilterDeployementTyp from 'components/FilterDeployementTyp';
import ReactPaginate from 'react-paginate';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled, Drawer, Divider, Grid, Modal } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import { useMyContext } from '../../MyContext';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useData } from '../../DataContext';
import LinearProgress from '@mui/material/LinearProgress';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { TextField, MenuItem, Select, TableHead } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useSortedData } from 'SortedDataProvider';
import axios from 'axios';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const theme = createTheme({
  palette: {
    primary: {
      light: '#005B7F',
      main: '#11998E',
      dark: '#005946',
      contrastText: '#fff',
    },
  },
});

const myComponent = {
  height: '1000px',
  overflow: 'scroll',
};

//from here MoreInfoTable
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
//bis hier

//Tabs function
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//bis hier
export default function Stepper3() {
  const { isDeutsch } = useLanguage();
  const { responseData } = useData();
  const { connectors, recommendationScores } = responseData || {};
  const Connectors = connectors || [];

  const serviceLevel = [
    {
      value: 0,
      label: isDeutsch ? 'alle' : 'all',
    },
    {
      value: 33,
      label: 'Caas',
    },
    {
      value: 67,
      label: 'Paas',
    },
    {
      value: 100,
      label: 'Self service',
    },
  ];

  const itKnowhow = [
    {
      value: 0,
      label: isDeutsch ? 'alle' : 'all',
    },
    {
      value: 33,
      label: isDeutsch ? 'niedrig' : 'low',
    },
    {
      value: 67,
      label: isDeutsch ? 'mittel' : 'medium',
    },
    {
      value: 100,
      label: isDeutsch ? 'hoch' : 'high',
    },
  ];

  const basedOnODRL = [
    {
      value: 0,
      label: isDeutsch ? 'alle' : 'all',
    },
    {
      value: 50,
      label: isDeutsch ? 'ODRL basiert' : 'ODRL based',
    },
    {
      value: 100,
      label: isDeutsch ? 'Andere' : 'Other',
    },
  ];

  const gui = [
    {
      value: 0,
      label: isDeutsch ? 'alle' : 'all',
    },
    {
      value: 50,
      label: isDeutsch ? 'Ja' : 'Yes',
    },
    {
      value: 100,
      label: isDeutsch ? 'Nein' : 'No',
    },
  ];

  const fte = [
    {
      value: 0,
      label: isDeutsch ? 'alle' : 'all',
    },
    {
      value: 25,
      label: 'Person',
    },
    {
      value: 50,
      label: 'Team',
    },
    {
      value: 75,
      label: isDeutsch ? 'Großes Team' : 'Big team',
    },
    {
      value: 100,
      label: 'Depart- ment',
    },
  ];

  const sortOptions = [
    {
      value: 0,
      label: 'score',
    },
    {
      value: 33,
      label: 'price',
    },
    {
      value: 66,
      label: 'fte',
    },
    {
      value: 100,
      label: 'duration',
    },
  ];

  const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
      width: 16,
      height: 16,
      marginTop: 0,
      marginLeft: 7,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
    '& .MuiSlider-markLabel': {
      fontSize: '0.8rem',
      whiteSpace: 'normal',
      textAlign: 'center',
      lineHeight: 1.2,
      wordWrap: 'break-word',
      marginLeft: '5px',
    },
    '& .MuiSlider-mark': {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[400],
    },
    '& .MuiSlider-markActive': {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  const [serviceLevelFilter, setServiceLevelFilter] = useState<string>('all');
  const [itKnowhowFilter, setItKnowhowFilter] = useState<string>('all');
  const [odrlFilter, setOdrlFilter] = useState<boolean | null>(null);
  const [guiFilter, setGuiFilter] = useState<boolean | null>(null);
  const [fteFilter, setFteFilter] = useState<string>('all');
  const [priceFilter, setPriceFilter] = useState({ from: '', to: '' });
  const [durationFilter, setDurationFilter] = useState({
    durationUnitFrom: '',
    durationUnitTo: '',
    durationFrom: '',
    durationTo: '',
  });
  const [sortScoreAsc, setSortScoreAsc] = useState(true);
  const [sortPriceAsc, setSortPriceAsc] = useState(true);
  const { sortedData, updateSortedData } = useSortedData();
  const [sortAttribute, setSortAttribute] = useState<{ value: number; label: string }>({
    value: 0,
    label: 'score',
  });
  const [sortAsc, setSortAsc] = useState(true);
  const [sortFteAsc, setSortFteAsc] = useState(true);
  const [sortDurationAsc, setSortDurationAsc] = useState(true);
  const [sortOption, setSortOption] = useState('');
  const [sortDirection] = useState('');

  const handleServiceLevelChange = (event: Event, newValue: number | number[]) => {
    const selectedValue =
      newValue === 33
        ? 'caas'
        : newValue === 67
        ? 'paas'
        : newValue === 100
        ? 'self_service'
        : 'all';
    setServiceLevelFilter(selectedValue);
  };

  const handleItKnowhowChange = (event: Event, newValue: number | number[]) => {
    const selectedValue =
      newValue === 33 ? 'low' : newValue === 67 ? 'medium' : newValue === 100 ? 'high' : 'all';
    setItKnowhowFilter(selectedValue);
  };

  const handleOdrlChange = (event: Event, newODRL: number | number[]) => {
    const selectedODRL = newODRL === 50 ? true : newODRL === 100 ? false : null;
    setOdrlFilter(selectedODRL);
  };

  const handleGuiChange = (event: Event, newValue: number | number[]) => {
    const selectedValue = newValue === 50 ? true : newValue === 100 ? false : null;
    setGuiFilter(selectedValue);
  };

  const handleFteChange = (event: Event, newValue: number | number[]) => {
    const selectedValue =
      newValue === 25
        ? 'single_person'
        : newValue === 50
        ? 'small_team'
        : newValue === 75
        ? 'large_team'
        : newValue === 100
        ? 'department'
        : 'all';
    setFteFilter(selectedValue);
  };

  const handlePriceChange = (prop: any) => (event: any) => {
    setPriceFilter({ ...priceFilter, [prop]: event.target.value });
  };

  const handleDurationChange =
    (prop: keyof typeof durationFilter) =>
    (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      const value = (event.target as HTMLInputElement).value;
      const parsedValue =
        prop === 'durationUnitFrom' || prop === 'durationUnitTo'
          ? value.toString()
          : parseInt(value) || '';

      if (parsedValue === 'none' || parsedValue === '0') {
        const { [prop]: removedFilter, ...restFilters } = durationFilter;
        setDurationFilter({
          ...(restFilters as {
            durationUnitFrom: string;
            durationUnitTo: string;
            durationFrom: string;
            durationTo: string;
          }),
        });
      } else {
        setDurationFilter({
          ...durationFilter,
          [prop]: parsedValue,
        });
      }
    };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortOption(event.target.value);
  };

  useEffect(() => {
    const zippedData = Connectors.map((item: any, index: number) => ({
      ...item,
      recommendationScore: recommendationScores[index],
    }));

    const filteredData = zippedData.filter((item: any) => {
      if (
        (serviceLevelFilter === 'all' || item.serviceLevel.includes(serviceLevelFilter)) &&
        (itKnowhowFilter === 'all' || item.itKnowhow === itKnowhowFilter) &&
        (odrlFilter === null || item.basedOnODRL === odrlFilter) &&
        (guiFilter === null || item.gui === guiFilter) &&
        (fteFilter === 'all' || item.fte.includes(fteFilter)) &&
        (priceFilter.from === '' || item.price >= Number(priceFilter.from)) &&
        (priceFilter.to === '' || item.price <= Number(priceFilter.to)) &&
        ((durationFilter.durationUnitFrom === '' &&
          durationFilter.durationFrom === '' &&
          durationFilter.durationUnitTo === '' &&
          durationFilter.durationTo === '') ||
          (durationFilter.durationUnitFrom === 'days' &&
            durationFilter.durationUnitTo === 'days' &&
            item.durationUnit === 'days' &&
            item.durationFrom >= Number(durationFilter.durationFrom) &&
            item.durationTo <= Number(durationFilter.durationTo)) ||
          (durationFilter.durationUnitFrom === 'months' &&
            durationFilter.durationUnitTo === 'months' &&
            item.durationUnit === 'months' &&
            item.durationFrom >= Number(durationFilter.durationFrom) &&
            item.durationTo <= Number(durationFilter.durationTo)) ||
          (durationFilter.durationUnitFrom === 'days' &&
            durationFilter.durationUnitTo === 'months' &&
            ((item.durationUnit === 'days' &&
              item.durationFrom >= Number(durationFilter.durationFrom) &&
              item.durationTo <= Math.ceil(Number(durationFilter.durationTo) * 30)) || // Convert months to days
              (item.durationUnit === 'months' &&
                item.durationFrom >= Number(durationFilter.durationFrom) / 30 &&
                item.durationTo <= Number(durationFilter.durationTo)))) ||
          (durationFilter.durationUnitFrom === 'months' &&
            durationFilter.durationUnitTo === 'days' &&
            ((item.durationUnit === 'months' &&
              item.durationFrom >= Number(durationFilter.durationFrom) &&
              item.durationTo <= Math.ceil(Number(durationFilter.durationTo) / 30)) || // Convert days to months
              (item.durationUnit === 'days' &&
                item.durationFrom >= Number(durationFilter.durationFrom) * 30 &&
                item.durationTo <= Number(durationFilter.durationTo)))) ||
          (durationFilter.durationUnitFrom === 'months' &&
            durationFilter.durationUnitTo === 'months' &&
            item.durationUnit === 'months' &&
            item.durationTo === Number(durationFilter.durationTo)))
      ) {
        return true;
      }
      return false;
    });

    let sorted = [...filteredData];

    switch (sortOption) {
      case 'score_high':
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection
            ? a.recommendationScore - b.recommendationScore
            : b.recommendationScore - a.recommendationScore,
        );
        break;
      case 'score_low':
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection
            ? b.recommendationScore - a.recommendationScore
            : a.recommendationScore - b.recommendationScore,
        );
        break;
      case 'fte_low':
        const fteOrderLow: { [key: string]: number } = {
          single_person: 1,
          small_team: 2,
          large_team: 3,
          department: 4,
        };
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection
            ? fteOrderLow[b.fte] - fteOrderLow[a.fte]
            : fteOrderLow[a.fte] - fteOrderLow[b.fte],
        );
        break;
      case 'fte_high':
        const fteOrderHigh: { [key: string]: number } = {
          department: 1,
          large_team: 2,
          small_team: 3,
          single_person: 4,
        };
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection
            ? fteOrderHigh[b.fte] - fteOrderHigh[a.fte]
            : fteOrderHigh[a.fte] - fteOrderHigh[b.fte],
        );
        break;
      case 'price_high':
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection ? a.price - b.price : b.price - a.price,
        );
        break;
      case 'price_low':
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection ? b.price - a.price : a.price - b.price,
        );
        break;
      case 'duration_high':
        sorted = sorted.sort((a: any, b: any) => {
          if (a.durationUnit === 'days' && b.durationUnit === 'months') {
            return sortDirection ? -1 : 1;
          } else if (a.durationUnit === 'months' && b.durationUnit === 'days') {
            return sortDirection ? 1 : -1;
          } else if (a.durationFrom === b.durationFrom) {
            return sortDirection ? a.durationTo - b.durationTo : b.durationTo - a.durationTo;
          } else {
            return sortDirection
              ? a.durationFrom - b.durationFrom
              : b.durationFrom - a.durationFrom;
          }
        });
        break;
      case 'duration_low':
        sorted = sorted.sort((a: any, b: any) => {
          if (a.durationUnit === 'days' && b.durationUnit === 'months') {
            return sortDirection ? 1 : -1;
          } else if (a.durationUnit === 'months' && b.durationUnit === 'days') {
            return sortDirection ? -1 : 1;
          } else if (a.durationFrom === b.durationFrom) {
            return sortDirection ? b.durationTo - a.durationTo : a.durationTo - b.durationTo;
          } else {
            return sortDirection
              ? b.durationFrom - a.durationFrom
              : a.durationFrom - b.durationFrom;
          }
        });
        break;
      default:
        sorted = sorted.sort((a: any, b: any) =>
          sortDirection
            ? a.recommendationScore - b.recommendationScore
            : b.recommendationScore - a.recommendationScore,
        );
    }

    updateSortedData(sorted);
  }, [
    Connectors,
    recommendationScores,
    sortOption,
    sortDirection,
    sortScoreAsc,
    sortPriceAsc,
    sortFteAsc,
    sortDurationAsc,
    serviceLevelFilter,
    itKnowhowFilter,
    odrlFilter,
    guiFilter,
    fteFilter,
    priceFilter,
    durationFilter,
  ]);

  const [pagination, setPagination] = useState({
    data: sortedData,
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [],
  });

  useEffect(() => {
    if (sortedData.length > 0) {
      setPagination((prevState: any) => ({
        ...prevState,
        pageCount: Math.ceil(sortedData.length / prevState.numberPerPage),
        data: sortedData,
        currentData: sortedData.slice(prevState.offset, prevState.offset + prevState.numberPerPage),
      }));
    }
  }, [sortedData, pagination.numberPerPage, pagination.offset]);

  useEffect(() => {
    // Scroll to the top of the page when pagination offset changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pagination.offset]);

  const handlePageClick = (event: any) => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const drawerWidth = 250;
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  //Popup and Table
  const [selectedConnectorIndex, setSelectedConnectorIndex] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(new Array(Connectors.length).fill(false));

  //Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //Mobile and Tablet Views
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isTablet, setIsTablet] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* const { aufwandThree } = useMyContext(); */
  //Filter
  const {
    aufwandOne,
    aufwandTwo,
    aufwandThree,
    aufwandFour,
    aufwandFive,
    aufwandSix,
    aufwandSeven,
    companyName,
    industrySector,
    companyType,
    companyLocation,
    companyZipcode,
    selectedCountry,
    contactFirstname,
    contactLastname,
    contactEmail,
    companyItExpertsFrom,
    companyItExpertsTo,
  } = useMyContext();

  const [connectorMedia, setConnectorMedia] = useState<any[]>([]);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseURL}/api/connectors?populate=connectorLogo`)
      .then((response) => {
        console.log('Connector media data:', response.data);
        setConnectorMedia(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Connector media:', error);
      });
  }, []);

  const getConnectorLogoUrl = (connectorName: string) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const mediaItem = connectorMedia.find(
      (media: any) => media.attributes.connectorName === connectorName,
    );

    if (mediaItem && mediaItem.attributes.connectorLogo?.data) {
      return `${baseURL}${mediaItem.attributes.connectorLogo.data.attributes.url}`;
    }

    return '';
  };

  const [selectedConnectors, setSelectedConnectors] = useState<any[]>([]);
  const [isCompareOpen, setIsCompareOpen] = React.useState(
    new Array(Connectors.length).fill(false),
  );

  const handleCompareClick = (ind: number) => {
    const newModalStates = [...isCompareOpen];
    newModalStates[ind] = true;
    setIsCompareOpen(newModalStates);
  };
  const handleCloseCompare = (ind: number) => {
    const newModalStates = [...isCompareOpen];
    newModalStates[ind] = false;
    setIsCompareOpen(newModalStates);
    setSelectedConnectors([]);
  };
  const handleSelectConnector = (connector: any) => {
    setSelectedConnectors((prevSelected) =>
      prevSelected.find((item) => item.connectorName === connector.connectorName)
        ? prevSelected.filter((item) => item.connectorName !== connector.connectorName)
        : [...prevSelected, connector],
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          maxWidth: '2000px',
          margin: '0 auto',
          height: '3100',
        }}
      >
        <div
          style={{
            flexGrow: '15',
            padding: '0px',
            marginRight: '0px',
          }}
        >
          <Typography variant='h6' gutterBottom>
            {isDeutsch
              ? translationFunction().deutschTranslations.stepper31
              : translationFunction().englishTranslations.stepper31}
          </Typography>
          <List disablePadding /* style={myComponent} */>
            <div>
              {!responseData ? (
                <LinearProgress />
              ) : (
                pagination.currentData &&
                pagination.currentData.map((item: any, index: any) => {
                  const handleDialogOpen = (ind: any) => {
                    setSelectedConnectorIndex(index);
                    const newDialogStates = [...openDialog];
                    newDialogStates[ind] = true;
                    setOpenDialog(newDialogStates);
                  };
                  const handleDialogClose = (ind: any) => {
                    const newDialogStates = [...openDialog];
                    newDialogStates[ind] = false;
                    setOpenDialog(newDialogStates);
                  };
                  const isSelected = selectedConnectors.find(
                    (connector) => connector.connectorName === item.connectorName,
                  );
                  const itemsTrueGreen = [];
                  const itemsYellow = [];
                  const itemsFalseRed = [];

                  if (item?.gui) {
                    itemsTrueGreen.push('GUI');
                  } else {
                    itemsFalseRed.push('GUI');
                  }

                  if (item?.hasDocumentation) {
                    itemsTrueGreen.push('Dokumentation');
                  } else {
                    itemsFalseRed.push('Dokumentation');
                  }
                  if (
                    item?.itKnowhow === aufwandThree ||
                    aufwandThree === 'high' ||
                    aufwandThree == 'medium'
                  ) {
                    itemsTrueGreen.push('IT-Know-How');
                  } else if (item?.itKnowhow === 'medium' && aufwandThree === 'low') {
                    itemsYellow.push('IT-Know-How');
                  } else {
                    itemsFalseRed.push('IT-Know-How');
                  }

                  if (item?.hasSupport) {
                    itemsTrueGreen.push('Support');
                  } else {
                    itemsFalseRed.push('Support');
                  }
                  const allItems = [
                    ...itemsTrueGreen.map((item) => ({
                      name: item,
                      color: 'green',
                      Icon: CheckCircleOutlineRoundedIcon,
                    })),
                    ...itemsYellow.map((item) => ({
                      name: item,
                      color: 'darkorange',
                      Icon: CheckCircleOutlineRoundedIcon,
                    })),
                    ...itemsFalseRed.map((item) => ({
                      name: item,
                      color: 'red',
                      Icon: ErrorOutlineRoundedIcon,
                    })),
                  ];
                  const firstColumnItems = allItems.slice(0, 2);
                  const secondColumnItems = allItems.slice(2);
                  return (
                    <Paper
                      key={item.id}
                      elevation={0}
                      sx={{
                        p: 1,
                        marginBottom: '10px',
                      }}
                    >
                      <Paper
                        elevation={6}
                        key={item.id}
                        sx={{ py: 1, px: 0 }}
                        style={{
                          borderColor: '#B4B4B4',
                          padding: '10px',
                          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.6)',
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={isMobile ? 12 : isTablet ? 3 : 3.3}
                            sx={{
                              paddingRight: isMobile ? 0 : '10px',
                              marginRight: isMobile ? 0 : '10px',
                              borderRight: isMobile ? 'none' : '1px solid #d3d3d3',
                            }}
                          >
                            <div style={{ color: 'grey' }}>{`${
                              index + 1 + pagination.offset
                            }.`}</div>
                            <p></p>
                            <p
                              style={{
                                marginTop: '-8px',
                                marginLeft: '-5px',
                                height: '100px',
                                width: '100px',
                              }}
                            >
                              <img
                                key={item.connectorName}
                                src={getConnectorLogoUrl(item.connectorName)}
                                alt={`Logo für ${item.connectorName}`}
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  width: 'auto',
                                  height: 'auto',
                                }}
                              />
                            </p>
                            {isMobile ? (
                              <Typography
                                variant='h6'
                                sx={{ textAlign: 'start', fontWeight: 'bold' }}
                              >
                                {item?.connectorName}
                              </Typography>
                            ) : (
                              ''
                            )}
                            <p>{item?.connectorMaintainer}</p>
                            <p>
                              {Array.isArray(item?.serviceLevel)
                                ? item.serviceLevel
                                    .map((level: string, index: number) =>
                                      level === 'caas'
                                        ? 'Connector-as-a-Service'
                                        : level === 'paas'
                                        ? 'Platform-as-a-Service'
                                        : 'Self Service',
                                    )
                                    .join(', ')
                                : 'Service level'}
                            </p>
                            <Grid item xs={isMobile ? 12 : isTablet ? 7 : 12} md={11.1}>
                              <Typography
                                onClick={() => handleDialogOpen(index)}
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: '#11998E',
                                }}
                              >
                                {isDeutsch
                                  ? translationFunction().deutschTranslations.stepper35
                                  : translationFunction().englishTranslations.stepper35}{' '}
                              </Typography>
                              <BootstrapDialog
                                onClose={() => handleDialogClose(index)}
                                aria-labelledby='customized-dialog-title'
                                aria-describedby='alert-dialog-description'
                                open={openDialog[index]}
                                maxWidth='lg'
                              >
                                <BootstrapDialogTitle
                                  id='customized-dialog-title'
                                  onClose={() => handleDialogClose(index)}
                                >
                                  {isDeutsch
                                    ? translationFunction().deutschTranslations.stepper36
                                    : translationFunction().englishTranslations.stepper36}{' '}
                                  {selectedConnectorIndex !== null ? item.connectorName : ''}
                                </BootstrapDialogTitle>
                                <DialogContent dividers>
                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs
                                      value={value}
                                      onChange={handleChange}
                                      aria-label='basic tabs example'
                                      orientation={isMobile ? 'vertical' : 'horizontal'}
                                    >
                                      <Tab
                                        label={
                                          isDeutsch
                                            ? translationFunction().deutschTranslations.stepper37
                                            : translationFunction().englishTranslations.stepper37
                                        }
                                        {...a11yProps(0)}
                                      />
                                      <Tab
                                        label={
                                          isDeutsch
                                            ? translationFunction().deutschTranslations.stepper38
                                            : translationFunction().englishTranslations.stepper38
                                        }
                                        {...a11yProps(1)}
                                      />
                                      <Tab
                                        label={
                                          isDeutsch
                                            ? translationFunction().deutschTranslations.stepper39
                                            : translationFunction().englishTranslations.stepper39
                                        }
                                        {...a11yProps(2)}
                                      />
                                    </Tabs>
                                  </Box>
                                  <CustomTabPanel value={value} index={0}>
                                    {selectedConnectorIndex !== null && (
                                      <TableContainer
                                        component={Paper}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                      >
                                        <Table
                                          sx={{ minWidth: isMobile ? 400 : 700 }}
                                          size='small'
                                          aria-label='a dense table'
                                        >
                                          <TableBody>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Name
                                              </TableCell>
                                              <TableCell>{item.connectorName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper310
                                                  : translationFunction().englishTranslations
                                                      .stepper310}
                                              </TableCell>
                                              <TableCell>
                                                {/* {
                                                  Connectors[selectedConnectorIndex]
                                                    .connectorDescription
                                                } */}
                                                {item.connectorDescription}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Zahlung' : 'Payment'}
                                              </TableCell>
                                              <TableCell>
                                                {item.payment === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Preismodell' : 'Pricing Model'}
                                              </TableCell>
                                              <TableCell>{item.pricingModel}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper311
                                                  : translationFunction().englishTranslations
                                                      .stepper311}
                                              </TableCell>
                                              <TableCell>{item.price} Euro</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Preis Info' : 'Price Info'}
                                              </TableCell>
                                              <TableCell>{item.priceInfo}</TableCell>
                                            </TableRow>
                                            {/* <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Zahlungsintervall'
                                                  : 'Payment Interval'}
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item.paymentInterval)
                                                  ? item.paymentInterval
                                                      .map((protocol: string) =>
                                                        protocol.replace(/_/g, ' '),
                                                      )
                                                      .join(', ')
                                                  : item.paymentInterval
                                                  ? item.paymentInterval.replace(/_/g, ' ')
                                                  : ''}
                                              </TableCell>
                                            </TableRow> */}
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Abonnementbeschreibung'
                                                  : 'Abonnement Description'}
                                              </TableCell>
                                              <TableCell>{item.abonnementDescription}</TableCell>
                                            </TableRow>
                                            {/* <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Kostenberechnungsbasis'
                                                  : 'Cost Calculation Basis'}
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item.costCalculationBasis)
                                                  ? item.costCalculationBasis
                                                      .map((protocol: string) =>
                                                        protocol.replace(/_/g, ' '),
                                                      )
                                                      .join(', ')
                                                  : item.costCalculationBasis
                                                  ? item.costCalculationBasis.replace(/_/g, ' ')
                                                  : ''}
                                              </TableCell>
                                            </TableRow> */}
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Matching zu Benutzerangaben'
                                                  : 'Matching to user information'}
                                              </TableCell>
                                              <TableCell>
                                                {
                                                  /* recommendationScores[index] */ item.recommendationScore !==
                                                  undefined
                                                    ? `${Math.floor(
                                                        /* recommendationScores[
                                                        index + pagination.offset
                                                      ] */ item.recommendationScore * 100,
                                                      )}% ${isDeutsch ? 'Score' : 'Score'}`
                                                    : ''
                                                }
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Vorname' : 'First Name'}
                                              </TableCell>
                                              <TableCell>{item.contactForename}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Nachname' : 'Last Name'}
                                              </TableCell>
                                              <TableCell>{item.contactName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch ? 'Kontakt Email' : 'Contact Email'}
                                              </TableCell>
                                              <TableCell>{item.connectorEmail}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper312
                                                  : translationFunction().englishTranslations
                                                      .stepper312}
                                              </TableCell>
                                              <TableCell>{item.contactLocation}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Website
                                              </TableCell>
                                              <TableCell>{item.connectorWebsite}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </CustomTabPanel>
                                  <CustomTabPanel value={value} index={1}>
                                    {selectedConnectorIndex !== null && (
                                      <TableContainer
                                        component={Paper}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                      >
                                        <Table
                                          sx={{ minWidth: isMobile ? 400 : 700 }}
                                          size='small'
                                          aria-label='a dense table'
                                        >
                                          <TableBody>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Open Source
                                              </TableCell>
                                              <TableCell>{item.openSource}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper314
                                                  : translationFunction().englishTranslations
                                                      .stepper314}
                                              </TableCell>
                                              <TableCell>
                                                {item.license === 'apache_2_0'
                                                  ? 'Apache 2.0'
                                                  : item.license === 'mit_license'
                                                  ? isDeutsch
                                                    ? 'Mit Lizenz'
                                                    : 'With License'
                                                  : item.license === 'bsd_license'
                                                  ? 'BSD License'
                                                  : item.license === 'unlicense'
                                                  ? isDeutsch
                                                    ? 'Ohne Lizenz'
                                                    : 'Unlicense'
                                                  : item.license === 'mozilla_public_license_2_0'
                                                  ? 'Mozilla Public License 2.0'
                                                  : item.license === 'eclipse_public_license_2_0'
                                                  ? 'Eclipse Public License 2.0'
                                                  : item.license === 'gnu_lgpl'
                                                  ? 'GNU LGPL'
                                                  : item.licence === 'gnu_agpl'
                                                  ? 'GNU AGPL'
                                                  : item.licence === 'gnu_gpl_3'
                                                  ? 'GNU GPL 3'
                                                  : ''}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                GUI
                                              </TableCell>
                                              <TableCell>
                                                {item.gui === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper315
                                                  : translationFunction().englishTranslations
                                                      .stepper315}
                                              </TableCell>
                                              <TableCell>
                                                {item.dsSpecificGui === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Selbstimplementierung'
                                                  : 'Self-implementation'}
                                              </TableCell>
                                              <TableCell>
                                                {item.selfImplementation === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper316
                                                  : translationFunction().englishTranslations
                                                      .stepper316}
                                              </TableCell>
                                              <TableCell>
                                                {item.cloudNeeded === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Cloud Provider
                                              </TableCell>
                                              <TableCell>
                                                {item.cloud === 'aws'
                                                  ? 'AWS'
                                                  : item.cloud === 'microsoft_azure'
                                                  ? 'Microsoft Azure'
                                                  : item.cloud === 'google_cloud'
                                                  ? 'Google Cloud'
                                                  : item.cloud === 'ibm_cloud'
                                                  ? 'IBM Cloud'
                                                  : item.cloud === 'redhat'
                                                  ? 'Red Hat'
                                                  : item.cloud === 'vmware'
                                                  ? 'VMware'
                                                  : item.cloud === 'mycloud'
                                                  ? 'My Cloud'
                                                  : ''}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper317
                                                  : translationFunction().englishTranslations
                                                      .stepper317}
                                              </TableCell>
                                              <TableCell>
                                                {item.basedOnODRL === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper318
                                                  : translationFunction().englishTranslations
                                                      .stepper318}
                                              </TableCell>
                                              <TableCell>
                                                {item.alternativePolicyExpressionModel}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper320
                                                  : translationFunction().englishTranslations
                                                      .stepper320}
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item?.usedProtocols)
                                                  ? item.usedProtocols
                                                      .map((protocol: string) =>
                                                        protocol === 'idscp_v2'
                                                          ? 'idscp v2'
                                                          : protocol === 'ids_rest'
                                                          ? 'ids rest'
                                                          : protocol,
                                                      )
                                                      .join(', ')
                                                  : 'Used Protocols'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper321
                                                  : translationFunction().englishTranslations
                                                      .stepper321}
                                              </TableCell>
                                              <TableCell>{item.trl}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Eingehende Datenverbindungen'
                                                  : 'Incoming data connections'}
                                              </TableCell>
                                              <TableCell>
                                                {item.dataConnections === 21
                                                  ? isDeutsch
                                                    ? 'Unbegrenzt'
                                                    : 'Unlimited'
                                                  : item.dataConnections}
                                                {isDeutsch
                                                  ? ' Aktive Verbindungen'
                                                  : ' Active Connections'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Monatliches Datenübertragungsvolumen'
                                                  : 'Monthly data transfer volume'}
                                              </TableCell>
                                              <TableCell>
                                                {item.incomingData === 101
                                                  ? isDeutsch
                                                    ? 'Unbegrenzt'
                                                    : 'Unlimited'
                                                  : item.incomingData}
                                                {' GB'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? 'Anzahl der Benutzerkonten'
                                                  : 'Number of user accounts'}
                                              </TableCell>
                                              <TableCell>
                                                {item.userAmount === 11
                                                  ? isDeutsch
                                                    ? 'Unbegrenzt'
                                                    : 'Unlimited'
                                                  : item.userAmount}
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </CustomTabPanel>
                                  <CustomTabPanel value={value} index={2}>
                                    {selectedConnectorIndex !== null && (
                                      <TableContainer
                                        component={Paper}
                                        style={{ width: '100%', marginBottom: '20px' }}
                                      >
                                        <Table
                                          sx={{ minWidth: isMobile ? 400 : 700 }}
                                          size='small'
                                          aria-label='a dense table'
                                        >
                                          <TableBody>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper322
                                                  : translationFunction().englishTranslations
                                                      .stepper322}
                                              </TableCell>
                                              <TableCell>
                                                {item.connectorType === 'data_connector_framework'
                                                  ? 'Data Connector Framework'
                                                  : item.connectorType ===
                                                    'generic_opensource_solution'
                                                  ? 'Generic Opensource Solution'
                                                  : item.connectorType === 'generic_solution'
                                                  ? 'Generic Solution'
                                                  : item.connectorType === 'off_the_shelf_solution'
                                                  ? 'Off-the-shelf Solution'
                                                  : ''}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Version
                                              </TableCell>
                                              <TableCell>{item.connectorVersion}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper323
                                                  : translationFunction().englishTranslations
                                                      .stepper323}
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item?.deploymentType)
                                                  ? item.deploymentType
                                                      .map(
                                                        (deployment: string, index: number) =>
                                                          deployment,
                                                      )
                                                      .join(', ')
                                                  : 'Deployment Type'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper324
                                                  : translationFunction().englishTranslations
                                                      .stepper324}
                                              </TableCell>
                                              <TableCell>
                                                {item.regionalRestrictions === true
                                                  ? isDeutsch
                                                    ? 'Ja'
                                                    : 'Yes'
                                                  : isDeutsch
                                                  ? 'Nein'
                                                  : 'No'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper325
                                                  : translationFunction().englishTranslations
                                                      .stepper325}
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item?.targetIndustrySectors)
                                                  ? item.targetIndustrySectors
                                                      .map(
                                                        (industry: string, index: number) =>
                                                          industry,
                                                      )
                                                      .join(', ')
                                                  : 'Deployment Type'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                Target Data Space Roles
                                              </TableCell>
                                              <TableCell>
                                                {Array.isArray(item?.targetDataspaceRoles)
                                                  ? item.targetDataspaceRoles
                                                      .map((industry: string) =>
                                                        industry === 'data_provider'
                                                          ? 'Data Provider'
                                                          : industry === 'data_consumer'
                                                          ? 'Data Consumer'
                                                          : industry === 'service_provider'
                                                          ? 'Service Provider'
                                                          : industry,
                                                      )
                                                      .join(', ')
                                                  : 'Deployment Type'}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell style={{ borderLeft: '1px solid #ccc' }}>
                                                {isDeutsch
                                                  ? translationFunction().deutschTranslations
                                                      .stepper327
                                                  : translationFunction().englishTranslations
                                                      .stepper327}
                                              </TableCell>
                                              <TableCell>{item.references}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </CustomTabPanel>
                                </DialogContent>
                              </BootstrapDialog>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : isTablet ? 7 : 12}
                            md={isMobile ? 12 : isTablet ? 3 : 6.2}
                            sx={{
                              paddingRight: isMobile ? 0 : '20px',
                              marginRight: isMobile ? 0 : '0px',
                              borderRight: isMobile ? 'none' : '1px solid #d3d3d3',
                              marginLeft: isMobile ? 0 : '14px',
                            }}
                          >
                            {isMobile ? (
                              ''
                            ) : (
                              <Typography
                                variant='subtitle1'
                                sx={{ textAlign: 'start', fontWeight: 'bold' }}
                              >
                                {item?.connectorName}
                              </Typography>
                            )}
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <div
                                style={{
                                  backgroundColor: '#d3d3d3',
                                  color: '#4a4a4a',
                                  padding: '5px 10px',
                                  borderRadius: '10px',
                                  display: 'inline-block',
                                }}
                              >
                                {isDeutsch
                                  ? translationFunction().deutschTranslations.stepper32
                                  : translationFunction().englishTranslations.stepper32}{' '}
                                <br />
                                {item?.durationFrom === item?.durationTo
                                  ? item?.durationFrom +
                                    (item?.durationUnit === 'months'
                                      ? isDeutsch
                                        ? ' Monat'
                                        : ' Month'
                                      : isDeutsch
                                      ? ' Tag'
                                      : ' Day')
                                  : (isDeutsch ? 'Von ' : 'From ') +
                                    item?.durationFrom +
                                    ' ' +
                                    (isDeutsch ? 'bis ' : 'to ') +
                                    item?.durationTo +
                                    ' ' +
                                    (item?.durationUnit === 'months'
                                      ? isDeutsch
                                        ? 'Monaten'
                                        : 'Months'
                                      : item?.durationUnit === 'days'
                                      ? isDeutsch
                                        ? 'Tagen'
                                        : 'Days'
                                      : null)}
                              </div>
                              <div
                                style={{
                                  backgroundColor: '#d3d3d3',
                                  color: '#4a4a4a',
                                  padding: '5px 10px',
                                  borderRadius: '10px',
                                  display: 'inline-block',
                                }}
                              >
                                {isDeutsch
                                  ? translationFunction().deutschTranslations.stepper32halb
                                  : translationFunction().englishTranslations.stepper32halb}{' '}
                                <br />
                                {item.fte === 'single_person'
                                  ? isDeutsch
                                    ? 'Eine Person'
                                    : 'Single Person'
                                  : item.fte === 'small_team'
                                  ? isDeutsch
                                    ? 'Kleines Team'
                                    : 'Small Team'
                                  : item.fte === 'large_team'
                                  ? isDeutsch
                                    ? 'Großes Team'
                                    : 'Large Team'
                                  : item.fte === 'department'
                                  ? isDeutsch
                                    ? 'Abteilung'
                                    : 'Department'
                                  : ''}
                              </div>
                            </div>
                            <p></p>
                            <br />
                            {/* <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{ display: 'flex', flexDirection: 'column', width: '45%' }}
                              >
                                {itemsTrueGreen.slice(0, 2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'green',
                                    }}
                                  >
                                    <CheckCircleOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}

                                {itemsYellow.slice(0, 2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'darkorange',
                                    }}
                                  >
                                    <CheckCircleOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}

                                {itemsFalseRed.slice(0, 2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'red',
                                    }}
                                  >
                                    <ErrorOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}
                              </div>

                              <div
                                style={{ display: 'flex', flexDirection: 'column', width: '45%' }}
                              >
                                {itemsTrueGreen.slice(2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'green',
                                    }}
                                  >
                                    <CheckCircleOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}

                                {itemsYellow.slice(2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'darkorange',
                                    }}
                                  >
                                    <CheckCircleOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}

                                {itemsFalseRed.slice(2).map((itemName) => (
                                  <div
                                    key={itemName}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: 'red',
                                    }}
                                  >
                                    <ErrorOutlineRoundedIcon style={{ marginRight: '5px' }} />
                                    {itemName}
                                  </div>
                                ))}
                              </div>
                            </div> */}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{ display: 'flex', flexDirection: 'column', width: '45%' }}
                              >
                                {firstColumnItems.map(({ name, color, Icon }) => (
                                  <div
                                    key={name}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: color,
                                    }}
                                  >
                                    <Icon style={{ marginRight: '5px' }} />
                                    {name}
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{ display: 'flex', flexDirection: 'column', width: '45%' }}
                              >
                                {secondColumnItems.map(({ name, color, Icon }) => (
                                  <div
                                    key={name}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: color,
                                    }}
                                  >
                                    <Icon style={{ marginRight: '5px' }} />
                                    {name}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {isMobile ? '' : isTablet ? '' : <br />}
                            <Button
                              variant='outlined'
                              sx={{ mt: 3, ml: 1 }}
                              style={{
                                marginTop: '20px',
                                color: isSelected ? '#11998E' : '#6e6e6e',
                                borderColor: isSelected ? '#11998E' : '#6e6e6e',
                              }}
                              startIcon={<CompareArrowsIcon />}
                              onClick={() => handleSelectConnector(item)}
                            >
                              {isSelected
                                ? isDeutsch
                                  ? 'Connector ausgewählt'
                                  : 'Selected Connector'
                                : isDeutsch
                                ? 'Connector vergleichen'
                                : 'Compare Connector'}
                            </Button>
                            {selectedConnectors.length > 0 && (
                              <Box
                                sx={{
                                  position: 'fixed',
                                  bottom: '10px',
                                  left: '50%', // Center horizontally
                                  transform: 'translateX(-50%)', // Adjust for width
                                  width: '80%', // Wider width (adjust as needed)
                                  maxWidth: '700px', // Max width to resemble Stepper size
                                  padding: '20px',
                                  boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
                                  backgroundColor: '#fff',
                                  zIndex: 1000,
                                  borderRadius: '8px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <h4>
                                  {isDeutsch
                                    ? 'Ihre Connector-Auswahl für den Vergleich'
                                    : 'Your connector selection for comparison'}
                                </h4>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    justifyContent: 'center', // Center items in the container
                                  }}
                                >
                                  {selectedConnectors.map((connector) => (
                                    <Box
                                      key={connector.connectorName}
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        flex: '0 0 calc(33.33% - 10px)', // 3 items per row with spacing
                                        marginBottom: '10px',
                                        height: '100px', // Set a uniform height for the entire box
                                      }}
                                    >
                                      <img
                                        src={getConnectorLogoUrl(connector.connectorName)}
                                        alt={`Logo for ${connector.connectorName}`}
                                        style={{
                                          width: '50px', // Fixed width
                                          height: '50px', // Fixed height
                                          objectFit: 'contain', // Keep the aspect ratio of the image
                                          marginBottom: '5px',
                                        }}
                                      />
                                      <span style={{ textAlign: 'center' }}>
                                        {connector.connectorName}
                                      </span>
                                    </Box>
                                  ))}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end', // Align button to the right
                                    marginTop: 'auto', // Push the button to the bottom
                                  }}
                                >
                                  <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => handleCompareClick(index)}
                                    style={{ marginTop: '10px' }}
                                  >
                                    {isDeutsch ? 'Vergleichen' : 'Compare'}
                                  </Button>
                                </Box>
                              </Box>
                            )}
                            <Modal
                              open={isCompareOpen[index]}
                              onClose={() => handleCloseCompare(index)}
                              aria-labelledby='compare-modal-title'
                              aria-describedby='compare-modal-description'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backdropFilter: 'blur(5px)',
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  width: '80%',
                                  maxHeight: '80%',
                                  overflowY: 'auto',
                                  backgroundColor: '#fff',
                                  boxShadow: 24,
                                  p: 4,
                                  borderRadius: '8px',
                                }}
                              >
                                <h2 id='compare-modal-title'>
                                  {isDeutsch ? 'Vergleich der Connectors' : 'Connector Comparison'}
                                </h2>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName} align='center'>
                                          <img
                                            key={connector.connectorName}
                                            src={getConnectorLogoUrl(connector.connectorName)}
                                            alt={`Logo for ${connector.connectorName}`}
                                            style={{
                                              maxWidth: '50px',
                                              maxHeight: '50px',
                                              display: 'block',
                                              margin: '0 auto',
                                            }}
                                          />
                                          <strong>{connector.connectorName}</strong>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Name' : 'Name'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorName}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Beschreibung' : 'Description'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorDescription}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Betreiber' : 'Maintainer'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorMaintainer}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Typ' : 'Type'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorType === 'data_connector_framework'
                                            ? 'Data Connector Framework'
                                            : connector.connectorType ===
                                              'generic_opensource_solution'
                                            ? 'Generic Opensource Solution'
                                            : connector.connectorType === 'generic_solution'
                                            ? 'Generic Solution'
                                            : connector.connectorType === 'off_the_shelf_solution'
                                            ? 'Off-the-shelf Solution'
                                            : ''}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Version' : 'Version'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorVersion}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Webseite' : 'Website'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorWebsite}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Email' : 'Email'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.connectorEmail}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Kontakt Vorname' : 'Contact Firstname'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.contactForename}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Kontakt Nachname' : 'Contact Lastname'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.contactName}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Ort' : 'Location'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.contactLocation}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Deployment Typ' : 'Deployment Type'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {Array.isArray(connector?.deploymentType)
                                            ? connector.deploymentType
                                                .map(
                                                  (deployment: string, index: number) => deployment,
                                                )
                                                .join(', ')
                                            : 'Deployment Type'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Open Source' : 'Open Source'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.openSource === 'closedsource'
                                            ? 'Closed Source'
                                            : connector.openSource === 'opensource'
                                            ? 'Open Source'
                                            : connector.openSource === 'opensource_copyleft'
                                            ? 'Open Source Copyleft'
                                            : 'Open Source Extendable'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Lizenz' : 'License'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.license === 'apache_2_0'
                                            ? 'Apache 2.0'
                                            : connector.license === 'mit_license'
                                            ? isDeutsch
                                              ? 'Mit Lizenz'
                                              : 'With License'
                                            : connector.license === 'bsd_license'
                                            ? 'BSD License'
                                            : connector.license === 'unlicense'
                                            ? isDeutsch
                                              ? 'Ohne Lizenz'
                                              : 'Unlicense'
                                            : connector.license === 'mozilla_public_license_2_0'
                                            ? 'Mozilla Public License 2.0'
                                            : connector.license === 'eclipse_public_license_2_0'
                                            ? 'Eclipse Public License 2.0'
                                            : connector.license === 'gnu_lgpl'
                                            ? 'GNU LGPL'
                                            : connector.licence === 'gnu_agpl'
                                            ? 'GNU AGPL'
                                            : connector.licence === 'gnu_gpl_3'
                                            ? 'GNU GPL 3'
                                            : ''}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'FTE' : 'FTE'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.fte === 'single_person'
                                            ? isDeutsch
                                              ? 'Eine Person'
                                              : 'Single Person'
                                            : connector.fte === 'small_team'
                                            ? isDeutsch
                                              ? 'Kleines Team'
                                              : 'Small Team'
                                            : connector.fte === 'large_team'
                                            ? isDeutsch
                                              ? 'Großes Team'
                                              : 'Large Team'
                                            : connector.fte === 'department'
                                            ? isDeutsch
                                              ? 'Abteilung'
                                              : 'Department'
                                            : ''}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch
                                            ? 'Sebst Implementierung'
                                            : 'Self implementation'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.selfImplementation === true
                                            ? isDeutsch
                                              ? 'Ja'
                                              : 'Yes'
                                            : isDeutsch
                                            ? 'Nein'
                                            : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'GUI' : 'GUI'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.gui === true
                                            ? isDeutsch
                                              ? 'Ja'
                                              : 'Yes'
                                            : isDeutsch
                                            ? 'Nein'
                                            : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Spezifische GUI' : 'Specific GUI'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.dsSpecificGui === true
                                            ? isDeutsch
                                              ? 'Ja'
                                              : 'Yes'
                                            : isDeutsch
                                            ? 'Nein'
                                            : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Cloud gebraucht' : 'Cloud needed'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.cloudNeeded === true
                                            ? isDeutsch
                                              ? 'Ja'
                                              : 'Yes'
                                            : isDeutsch
                                            ? 'Nein'
                                            : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Cloud' : 'Cloud'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.cloud === 'aws'
                                            ? 'AWS'
                                            : connector.cloud === 'microsoft_azure'
                                            ? 'Microsoft Azure'
                                            : connector.cloud === 'google_cloud'
                                            ? 'Google Cloud'
                                            : connector.cloud === 'ibm_cloud'
                                            ? 'IBM Cloud'
                                            : connector.cloud === 'redhat'
                                            ? 'Red Hat'
                                            : connector.cloud === 'vmware'
                                            ? 'VMware'
                                            : connector.cloud === 'mycloud'
                                            ? 'My Cloud'
                                            : ''}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Branchen' : 'Industry Sectors'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.targetIndustrySectors?.join(', ')}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Data Space Rolle' : 'Data Space Roles'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {Array.isArray(connector?.targetDataspaceRoles)
                                            ? connector.targetDataspaceRoles
                                                .map((industry: string) =>
                                                  industry === 'data_provider'
                                                    ? 'Data Provider'
                                                    : industry === 'data_consumer'
                                                    ? 'Data Consumer'
                                                    : industry === 'service_provider'
                                                    ? 'Service Provider'
                                                    : industry,
                                                )
                                                .join(', ')
                                            : 'Deployment Type'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'IT Know How' : 'IT Know How'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.itKnowhow}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Preis' : 'Price'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.price}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Preis Info' : 'Price Info'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.priceInfo}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch
                                            ? 'Dokumentation vorhanden'
                                            : 'Has Documentation'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.hasDocumentation ? 'Yes' : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Untersützung vorhanden' : 'Has Support'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.hasSupport ? 'Yes' : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'ODRL Sprachmodell' : 'ODRL Language Model'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.basedOnODRL ? 'Yes' : 'No'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Protokolle' : 'Protocols'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {Array.isArray(item?.usedProtocols)
                                            ? connector.usedProtocols
                                                .map((protocol: string) =>
                                                  protocol === 'idscp_v2'
                                                    ? 'idscp v2'
                                                    : protocol === 'ids_rest'
                                                    ? 'ids rest'
                                                    : protocol,
                                                )
                                                .join(', ')
                                            : 'Used Protocols'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'TRL' : 'TRL'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.trl}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>{isDeutsch ? 'Referenzen' : 'Credentials'}</strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.references}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch ? 'Service Level' : 'Service Level'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {Array.isArray(connector?.serviceLevel)
                                            ? connector.serviceLevel
                                                .map((level: string, index: number) =>
                                                  level === 'caas'
                                                    ? 'Connector-as-a-Service'
                                                    : level === 'paas'
                                                    ? 'Platform-as-a-Service'
                                                    : 'Self Service',
                                                )
                                                .join(', ')
                                            : 'Service level'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch
                                            ? 'Eingehende Datenverbindungen'
                                            : 'Incoming data connections'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.dataConnections === 21
                                            ? isDeutsch
                                              ? 'Unbegrenzt'
                                              : 'Unlimited'
                                            : connector.dataConnections}
                                          {isDeutsch
                                            ? ' Aktive Verbindungen'
                                            : ' Active Connections'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch
                                            ? 'Monatliches Datenübertragungsvolumen'
                                            : 'Monthly data transfer volume'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.incomingData === 101
                                            ? isDeutsch
                                              ? 'Unbegrenzt'
                                              : 'Unlimited'
                                            : connector.incomingData}
                                          {' GB'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>
                                          {isDeutsch
                                            ? 'Anzahl der Benutzerkonten'
                                            : 'Number of user accounts'}
                                        </strong>
                                      </TableCell>
                                      {selectedConnectors.map((connector) => (
                                        <TableCell key={connector.connectorName}>
                                          {connector.userAmount === 11
                                            ? isDeutsch
                                              ? 'Unbegrenzt'
                                              : 'Unlimited'
                                            : connector.userAmount}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Modal>
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : isTablet ? 7 : 12}
                            md={isMobile ? 12 : isTablet ? 1 : 2.4}
                            style={{ marginLeft: '-20px' }}
                          >
                            <Typography variant='body1' align='right'>
                              <Grid>
                                <br />
                                <div
                                  style={{
                                    /* fontSize: '15px', */
                                    backgroundColor: '#a6bbc8',
                                    color: '#005b7f',
                                    padding: '5px 10px',
                                    borderRadius: '10px',
                                    display: 'inline-block',
                                    marginTop: '5px',
                                  }}
                                >
                                  {item.recommendationScore !== undefined
                                    ? `${Math.floor(item.recommendationScore * 100)}% Score`
                                    : ''}
                                </div>
                                {isMobile ? '' : isTablet ? '' : <br />}
                                {isMobile ? '' : isTablet ? '' : <br />}
                                {isMobile ? '' : isTablet ? '' : <br />}
                                <div
                                  style={{
                                    color: 'grey',
                                    fontWeight: 'bold',
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: '22px',
                                    marginTop: '10px',
                                  }}
                                >
                                  <span
                                    style={{
                                      color: 'grey',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    {item.priceText}
                                  </span>

                                  {item?.price !== undefined && !isNaN(parseFloat(item.price)) && (
                                    <>
                                      {`${
                                        Math.floor(parseFloat(item.price)) === 0
                                          ? '0'
                                          : Math.floor(parseFloat(item.price))
                                      }`}
                                      <span
                                        style={{
                                          fontSize: '15px',
                                          paddingLeft: '1px',
                                          alignSelf: 'center',
                                          position: 'relative',
                                          bottom: '-2px',
                                        }}
                                      >
                                        {`,` +
                                          String(parseFloat(item.price).toFixed(2)).split('.')[1]}
                                        €
                                      </span>
                                    </>
                                  )}
                                  <Tooltip title={item.priceInfo} placement='top-start'>
                                    <InfoOutlinedIcon
                                      color='disabled'
                                      fontSize='small'
                                      style={{
                                        marginTop: '-10px',
                                        paddingTop: '5px',
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <br />
                                <text style={{ fontSize: '15px' }}>
                                  {isDeutsch ? 'Ø pro Monat' : 'Ø per Month'}
                                  <br />
                                  {/* {item.priceInfo} */}
                                </text>
                              </Grid>
                              <Grid
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginTop: '15px',
                                }}
                              >
                                <Button
                                  variant='contained'
                                  sx={{ mt: 3, ml: 1 }}
                                  href={item?.connectorWebsite}
                                  target='_blank'
                                >
                                  Weiter
                                </Button>
                              </Grid>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Paper>
                  );
                })
              )}
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'activePaginate'}
              />
            </div>
          </List>
        </div>
        <div id='drawer-container' style={{ position: 'relative' }}>
          <IconButton
            color='primary'
            aria-label='open drawer'
            edge='end'
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }) }}
          >
            <FilterListIcon />
          </IconButton>

          <Drawer
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                position: 'absolute',
                paddingLeft: '0px',
                transition: 'none !important',
              },
            }}
            variant='persistent'
            anchor='right'
            open={open}
            PaperProps={{ style: { position: 'absolute' } }}
            BackdropProps={{ style: { position: 'absolute', overflow: 'hidden' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'absolute', overflow: 'hidden' },
              keepMounted: true,
            }}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <div style={{ padding: '20px' }}>
              <Box display='flex' alignItems='center' width='220px'>
                <Typography>{isDeutsch ? 'Sortieren nach' : 'Sort by'}</Typography>
                <Select
                  value={sortOption}
                  onChange={handleSortChange}
                  style={{ width: '180px', height: '30px', marginLeft: '4px' }}
                >
                  <MenuItem value='score_high'>
                    {isDeutsch ? 'Score(Hoch)' : 'Score (High)'}
                  </MenuItem>
                  <MenuItem value='score_low'>
                    {isDeutsch ? 'Score(Niedrig)' : 'Score (Low)'}
                  </MenuItem>
                  <MenuItem value='fte_low'>{isDeutsch ? 'FTE (Niedrig)' : 'FTE (Low)'}</MenuItem>
                  <MenuItem value='fte_high'>{isDeutsch ? 'FTE (Hoch)' : 'FTE (High)'}</MenuItem>
                  <MenuItem value='price_low'>
                    {isDeutsch ? 'Preis (Niedrig)' : 'Price (Low)'}
                  </MenuItem>
                  <MenuItem value='price_high'>
                    {isDeutsch ? 'Preis (Hoch)' : 'Price (High)'}
                  </MenuItem>
                  <MenuItem value='duration_low'>
                    {isDeutsch ? 'Dauer (Niedrig)' : 'Duration (Low)'}
                  </MenuItem>
                  <MenuItem value='duration_high'>
                    {isDeutsch ? 'Dauer (Hoch)' : 'Duration (High)'}
                  </MenuItem>
                </Select>
              </Box>
              <br />
              <Box sx={{ width: 200 }}>
                <Typography>IT Knowhow</Typography>
                <StyledSlider
                  aria-label='IT Knowhow Filter'
                  value={
                    itKnowhowFilter === 'low'
                      ? 33
                      : itKnowhowFilter === 'medium'
                      ? 67
                      : itKnowhowFilter === 'high'
                      ? 100
                      : 0
                  }
                  onChange={(event, value) => handleItKnowhowChange(event, value as number)}
                  getAriaValueText={(value) => `${value}`}
                  step={null}
                  marks={itKnowhow}
                  track={false}
                />
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography>Service Level</Typography>
                {/* <StyledSlider
                  aria-label='Service Level Filter'
                  value={
                    serviceLevel.find((level) => level.label === serviceLevelFilter)?.value || 0
                  }
                  onChange={(event, value) => handleServiceLevelChange(event, value as number)}
                  getAriaValueText={(value) => `${value}`}
                  step={null}
                  marks={serviceLevel}
                  track={false}
                /> */}
                <StyledSlider
                  aria-label='Service Level Filter'
                  value={
                    serviceLevelFilter === 'caas'
                      ? 33
                      : serviceLevelFilter === 'paas'
                      ? 67
                      : serviceLevelFilter === 'self_service'
                      ? 100
                      : 0
                  }
                  onChange={(event, value) => handleServiceLevelChange(event, value as number)}
                  getAriaValueText={(value) => `${value}`}
                  step={null}
                  marks={serviceLevel}
                  track={false}
                />
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography>Usage Policies</Typography>
                <StyledSlider
                  aria-label='Usage Policies'
                  value={odrlFilter === null ? 0 : odrlFilter ? 50 : 100}
                  onChange={handleOdrlChange}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={basedOnODRL}
                  track={false}
                />
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography>Gui</Typography>
                <StyledSlider
                  aria-label='Gui'
                  value={guiFilter === null ? 0 : guiFilter ? 50 : 100} // Map boolean value to slider value
                  onChange={handleGuiChange}
                  step={null}
                  valueLabelDisplay='auto'
                  marks={gui}
                  track={false}
                />
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography>Arbeitsaufwand</Typography>
                {/* <StyledSlider
                  aria-label='FTE Filter'
                  value={fte.find((level) => level.label === fteFilter)?.value || 0}
                  onChange={(event, value) => handleFteChange(event, value as number)}
                  getAriaValueText={(value) => `${value}`}
                  step={null}
                  marks={fte}
                  track={false}
                /> */}
                <StyledSlider
                  aria-label='FTE Filter'
                  value={
                    fteFilter === 'single_person'
                      ? 25
                      : fteFilter === 'small_team'
                      ? 50
                      : fteFilter === 'large_team'
                      ? 75
                      : fteFilter === 'department'
                      ? 100
                      : 0
                  }
                  onChange={(event, value) => handleFteChange(event, value as number)}
                  getAriaValueText={(value) => `${value}`}
                  step={null}
                  marks={fte}
                  track={false}
                />
              </Box>
              <br />
              <Box sx={{ width: 200, '& > :not(style) + :not(style)': { marginTop: '10px' } }}>
                <TextField
                  label={isDeutsch ? 'Preis von' : 'Price From'}
                  type='number'
                  value={priceFilter.from}
                  onChange={handlePriceChange('from')}
                  fullWidth
                  sx={{ width: '220px' }}
                />
                <TextField
                  label={isDeutsch ? 'Preis bis' : 'Price To'}
                  type='number'
                  value={priceFilter.to}
                  onChange={handlePriceChange('to')}
                  fullWidth
                  sx={{ width: '220px' }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label={isDeutsch ? 'Von' : 'From'}
                      type='number'
                      value={durationFilter.durationFrom}
                      onChange={handleDurationChange('durationFrom')}
                      fullWidth
                      sx={{ width: '110px', marginBottom: '8px' }}
                    />
                    <TextField
                      label={isDeutsch ? 'bis' : 'To'}
                      type='number'
                      value={durationFilter.durationTo}
                      onChange={handleDurationChange('durationTo')}
                      fullWidth
                      sx={{ width: '110px' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label={isDeutsch ? 'Einheit' : 'Unit'}
                      value={durationFilter.durationUnitFrom}
                      onChange={handleDurationChange('durationUnitFrom')}
                      fullWidth
                      sx={{ width: '110px', marginBottom: '8px' }}
                    >
                      <MenuItem value=''>{isDeutsch ? 'Keine' : 'None'}</MenuItem>
                      <MenuItem value='days'>{isDeutsch ? 'Tag' : 'Day'}</MenuItem>
                      <MenuItem value='months'>{isDeutsch ? 'Monat' : 'Month'}</MenuItem>
                    </TextField>
                    <TextField
                      select
                      label={isDeutsch ? 'Einheit' : 'Unit'}
                      value={durationFilter.durationUnitTo}
                      onChange={handleDurationChange('durationUnitTo')}
                      fullWidth
                      sx={{ width: '110px' }}
                    >
                      <MenuItem value=''>{isDeutsch ? 'Keine' : 'None'}</MenuItem>
                      <MenuItem value='days'>{isDeutsch ? 'Tag' : 'Day'}</MenuItem>
                      <MenuItem value='months'>{isDeutsch ? 'Monat' : 'Month'}</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <br />
              {/* <Box display='flex' alignItems='center' width='220px'>
                <Typography>Sortieren nach</Typography>
                <Select
                  value={sortOption}
                  onChange={handleSortChange}
                  style={{ width: '180px', height: '30px', marginLeft: '4px' }}
                >
                  <MenuItem value='score_high'>Score (High)</MenuItem>
                  <MenuItem value='score_low'>Score (Low)</MenuItem>
                  <MenuItem value='fte_low'>FTE (Low)</MenuItem>
                  <MenuItem value='fte_high'>FTE (High)</MenuItem>
                  <MenuItem value='price_low'>Price (Low)</MenuItem>
                  <MenuItem value='price_high'>Price (High)</MenuItem>
                  <MenuItem value='duration_low'>Duration (Low)</MenuItem>
                  <MenuItem value='duration_high'>Duration (High)</MenuItem>
                </Select>
              </Box> */}
            </div>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
}
