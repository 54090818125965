import { useEffect } from 'react';

function RedirectToStrapiAdmin() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    window.location.href = `${baseURL}/admin`;
  }, []);

  return null;
}

export default RedirectToStrapiAdmin;
