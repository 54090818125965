import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Kontakt from 'pages/InputForm/Kontakt';
import PrivacyText from 'pages/InputForm/PrivacyText';
import Impressum from 'pages/InputForm/Impressum';
import Slide from '@mui/material/Slide';
import { useLanguage } from '../../LanguageContext';
import translationFunction from 'translationFunction';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';

const Section3 = () => {
  const [section3Content, setSection3Content] = useState<any>({});
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/api/section3?populate=logo1,logo2,logo3`)
      .then((response) => {
        console.log('Section3 data:', response.data);
        setSection3Content(response.data.data.attributes);
      })
      .catch((error) => {
        console.error('Error fetching section3 content:', error);
      });
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: section3Content.primaryColorLight || '#005B7F',
        main: section3Content.primaryColorMain || '#009374',
        dark: section3Content.primaryColorDark || '#005946',
        contrastText: section3Content.primaryContrastText || '#fff',
      },
      secondary: {
        light: section3Content.secondaryColorLight || '#0080b2',
        main: section3Content.secondaryColorMain || '#005B7F',
        dark: section3Content.secondaryColorDark || '#0080b2',
        contrastText: section3Content.secondaryContrastText || '#fff',
      },
    },
  });

  const { isDeutsch } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1535);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1535);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const sectionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !animationPlayed) {
          setIsInView(true);
          setAnimationPlayed(true);
        } else {
          setIsInView(false);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const section = sectionRef.current;
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, [animationPlayed]);
  return (
    <ThemeProvider theme={theme}>
      <div style={{ overflow: 'hidden', maxWidth: '2000px', margin: '0 auto' }}>
        <Grid
          className='conatiner'
          container
          spacing={8}
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent='center'
          alignContent='center'
          marginTop={isMobile ? '100px' : isTablet ? '100px' : '150px'}
        >
          <Grid ref={sectionRef} item xs={isMobile ? 12 : isTablet ? 5 : 12} xl={3}>
            <Slide direction='right' in={isMobile ? true : animationPlayed} timeout={1000}>
              <Card
                sx={{
                  width: isMobile ? 305 : 405,
                  height: isMobile ? 450 : 500,
                }}
              >
                <CardMedia
                  sx={{ height: 190, backgroundSize: '70%' }}
                  image={`${baseURL}${section3Content.logo1?.data?.attributes?.url}`}
                  style={{ backgroundColor: section3Content.cardColor }}
                />
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      textAlign='center'
                      width={isDeutsch ? '250px' : '200px'}
                    >
                      {isDeutsch ? section3Content.text1 : section3Content.text1En}
                      <br />
                      <br />
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', mt: '0px', alignItems: 'stretch' }}>
                  <Button size='small' target='_blank' href='https://www.isst.fraunhofer.de/'>
                    {section3Content.linkText1}
                  </Button>
                </CardActions>
              </Card>
            </Slide>
          </Grid>
          <Grid ref={sectionRef} item xs={isMobile ? 12 : isTablet ? 5 : 12} xl={3}>
            <Slide
              direction={isTablet ? 'left' : 'up'}
              in={isMobile ? true : animationPlayed}
              timeout={1000}
            >
              <Card
                sx={{
                  width: isMobile ? 305 : 405,
                  height: isMobile ? 450 : 500,
                }}
              >
                <CardMedia
                  sx={{ height: 190, backgroundSize: '50%' }}
                  image={`${baseURL}${section3Content.logo2?.data?.attributes?.url}`}
                  style={{ backgroundColor: section3Content.cardColor }}
                />
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      textAlign='center'
                      width={isDeutsch ? '250px' : '200px'}
                    >
                      {isDeutsch ? section3Content.text2 : section3Content.text2En}
                      <br />
                      <br />
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: 'center',
                    mt: '10px',
                    alignItems: 'stretch',
                    marginTop: isMobile ? '-30px' : '',
                  }}
                >
                  <Button size='small' target='_blank' href='https://mobility-dataspace.eu/'>
                    {section3Content.linkText2}
                  </Button>
                </CardActions>
              </Card>
            </Slide>
          </Grid>
          <Grid ref={sectionRef} item xs={isMobile ? 12 : isTablet ? 5 : 12} xl={3}>
            <Slide
              direction={isTablet ? 'up' : 'left'}
              in={isMobile ? true : animationPlayed}
              timeout={1000}
            >
              <Card
                sx={{
                  width: isMobile ? 305 : 405,
                  height: isMobile ? 450 : 500,
                }}
              >
                <CardMedia
                  sx={{ height: 190, backgroundSize: '55%' }}
                  image={`${baseURL}${section3Content.logo3?.data?.attributes?.url}`}
                  style={{ backgroundColor: section3Content.cardColor }}
                />
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      textAlign='center'
                      width={isDeutsch ? '250px' : '290px'}
                    >
                      {isDeutsch ? section3Content.text3 : section3Content.text3En}
                      <br />
                      <br />
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: 'center',
                    mt: isDeutsch ? '45px' : '-25px',
                    alignItems: 'stretch',
                  }}
                >
                  <Button size='small' target='_blank' href='https://dssc.eu/'>
                    {section3Content.linkText3}
                  </Button>
                </CardActions>
              </Card>
            </Slide>
          </Grid>
        </Grid>
        <Typography
          variant='subtitle1'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{
            '@media (max-width: 600px)': {
              marginTop: '180px !important',
            },
            '@media (max-width: 1100px)': {
              marginTop: '150px !important',
            },
            '@media (max-width: 1535px)': {
              marginTop: '100px',
            },
            '@media (min-width: 1536px)': {
              marginTop: '50px',
            },
          }}
        >
          {isDeutsch ? section3Content.connectorAnbieter : section3Content.connectorAnbieterEn}
          <br />
          <Button
            component={RouterLink}
            to={'/reiter'}
            sx={{ textTransform: 'none', textAlign: 'center', mt: 2, fontSize: '16px' }}
          >
            {isDeutsch ? section3Content.anbieterLink : section3Content.anbieterLinkEn}
          </Button>
        </Typography>
      </div>
    </ThemeProvider>
  );
};
export default Section3;
